import React from 'react';
import styled from 'styled-components';
import { Menu, Avatar } from 'antd';
import {
  useAuthentication,
  useSelectedClient,
  useAuthorization
} from '../../../uni-comms-api/hooks/authentication';

export default function MenuHeader({ ...props }) {
  const { user } = useAuthentication();
  const { selectedClient } = useSelectedClient();
  const initials = user.firstName[0] + user.lastName[0];
  const fullName = user.firstName + ' ' + user.lastName;
  return (
    <MenuHeaderItem key="header" {...props}>
      <AvatarContainer>
        <Avatar>{initials}</Avatar>
        <UserInfo>
          <FullName>{fullName}</FullName>
          <Roles>
            {user &&
              user.clientRoles[selectedClient].map(role => (
                <Role key={role}>{role}</Role>
              ))}
          </Roles>
          {useAuthorization('internal') && <div hidden>internal</div>}
        </UserInfo>
      </AvatarContainer>
    </MenuHeaderItem>
  );
}

const MenuHeaderItem = styled(Menu.Item)`
  margin-top: 10px;
  right: -7px;
  width: 260px;

  ::after {
    content: '';
    position: absolute;
    bottom: 99%;
    right: 16px;
    border: 11px solid transparent;
    border-bottom-color: white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  :hover {
    background-color: transparent;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FullName = styled.div`
  font-weight: 500;
  font-size: 16px;
`;

const Roles = styled.div`
  display: flex;
  width: 192px;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const Role = styled.div`
  background: #f9f9f9;
  border: 1px solid #f3eeee;
  border-radius: 3px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 4px;
  margin-bottom: 4px;
`;

const UserInfo = styled.div`
  padding-left: 10px;
`;
