import React, {
  useEffect,
  createContext,
  useContext,
  useReducer,
  useState,
  useMemo
} from 'react';
import PropTypes, { string } from 'prop-types';
import reducer, { initialState, setLoading, setReady } from './reducer';
import injectMessenger from './injectMessenger';

const Messenger = createContext();

export function useMessenger(options = {}) {
  const [mounted, setMounted] = useState(false);
  const { ready, loading, messenger } = useContext(Messenger);
  const shouldLoad = options.loadWhen !== undefined ? options.loadWhen : true;
  delete options.loadWhen;

  useEffect(() => {
    if (!shouldLoad && mounted) {
      setMounted(false);
    }
  }, [shouldLoad, mounted]);

  useEffect(() => {
    if (shouldLoad && ready && !mounted) {
      window.chatBot.init({
        immediateLoad: true,
        client: messenger.client,
        pipeline: messenger.pipeline,
        metadata: {},
        ...options
      });

      setMounted(true);
    }
  }, [messenger, mounted, options, ready, shouldLoad]);

  return { ready, loading, mounted };
}

export function MessengerProvider({ messenger, children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!state.ready || !window.chatBot) {
      const staticJs =
        process.env.NODE_ENV === 'development'
          ? '/static/js/bundle.js'
          : '/static/js/main.js';

      injectMessenger(messenger.url + staticJs).then(() => {
        dispatch(setReady(true));
        dispatch(setLoading(false));
      });
    }
  }, [messenger, state.ready]);

  const value = useMemo(
    () => ({
      loading: state.loading,
      ready: state.ready,
      messenger
    }),
    [state.loading, state.ready, messenger]
  );

  return <Messenger.Provider value={value}>{children}</Messenger.Provider>;
}

MessengerProvider.propTypes = {
  messenger: PropTypes.shape({
    url: string.isRequired
  }).isRequired,
  children: PropTypes.node.isRequired
};
