import React from 'react';
export default function ConversationReviewMenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="17.408"
      height="14.009"
      viewBox="0 0 17.408 14.009"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_4868"
            data-name="Rectangle 4868"
            width="17.408"
            height="14.009"
            transform="translate(-1 -1)"
            strokeWidth="0.3"
          />
        </clipPath>
      </defs>
      <g
        id="Group_12036"
        data-name="Group 12036"
        transform="translate(1 1)"
        clipPath="url(#clipPath)"
      >
        <path
          id="Path_28471"
          data-name="Path 28471"
          d="M6.6,9.368H5.621a.468.468,0,0,0-.329.135l-2.2,2.17a.179.179,0,0,1-.286-.143V9.836a.468.468,0,0,0-.468-.468h-1a.41.41,0,0,1-.41-.41V1.347a.41.41,0,0,1,.41-.41h12.3a.41.41,0,0,1,.41.41V3.693a4.257,4.257,0,0,1,.937.947V1.347A1.348,1.348,0,0,0,13.642,0H1.347A1.348,1.348,0,0,0,0,1.347V8.958A1.348,1.348,0,0,0,1.347,10.3h.527V11.53a1.1,1.1,0,0,0,.649,1.013,1.121,1.121,0,0,0,.469.1,1.107,1.107,0,0,0,.723-.27l.024-.022L5.813,10.3H7.051a5.332,5.332,0,0,1-.575-1.018"
          strokeWidth="0.3"
        />
        <path
          id="Path_28472"
          data-name="Path 28472"
          d="M284.2,163.6l-2.04-2.04a3.472,3.472,0,1,0-.636.606l2.055,2.055a.439.439,0,0,0,.621-.621m-7.322-4.227a2.591,2.591,0,1,1,4.4,1.85.442.442,0,0,0-.042.037l-.022.024a2.589,2.589,0,0,1-4.338-1.911"
          transform="translate(-267.92 -151.337)"
          strokeWidth="0.3"
        />
      </g>
    </svg>
  );
}
