import { logger } from '../../../../utils/logger';

async function get(authURL, selectedClient, token, pipelineId) {
  return await fetch(
    `${authURL}/clients/${selectedClient}/pipeline/${pipelineId}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  );
}

export default async function getPipeline(
  authURL,
  selectedClient,
  token,
  pipelineId
) {
  try {
    const response = await get(authURL, selectedClient, token, pipelineId);

    if (response.ok) {
      const pipeline = await response.json();
      return pipeline;
    }
    const error = await response.json();
    logger(error.message);
  } catch {
    logger('Error connecting to server');
  }
}
