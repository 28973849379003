import React from 'react';
import { Modal, ModalProps } from 'antd';
import styled from 'styled-components';
import Button from '../../modules/flows/presentational/Button';
import background from '../assets/trymyai-clawson.svg';
import imgBullet1 from '../assets/trymyai-ss-1.svg';
import imgBullet2 from '../assets/trymyai-ss-2.svg';
import imgBullet3 from '../assets/trymyai-ss-3.svg';
import customBullet from '../assets/trymyai-ss-bullet.svg';
import { colors } from '../../constants';

type TryMyAiModalProps = ModalProps;

const TryMyAiModal = ({ ...modalProps }: TryMyAiModalProps) => {
  const descriptions = [
    {
      img: imgBullet1,

      texts: [
        'Your request has been seen before and you will be shown your published response'
      ]
    },
    {
      img: imgBullet2,

      texts: [
        'Your request is similar to one that has been seen before and you will be shown the response published',
        'Training will be added to your existing flow',
        'View and accept the training for the existing flow',
        'Reject the training if you feel it would be better as its own flow'
      ]
    },
    {
      img: imgBullet3,

      texts: [
        'This is a new request that hasn’t been seen before. Your AI will read through your content’ to try and find an accurate response',
        'If a response is found it will be shown and a new flow created and published. If you prefer not to automatically show a response you can turn this off and a draft flow will be created',
        'If a response is not found a draft flow will be created & you will be notified so you can add the response yourself'
      ]
    }
  ];

  return (
    <StyledModal
      centered
      footer={false}
      width={905}
      closeIcon={false}
      {...modalProps}
    >
      <CenteredImg src={background} />
      <Title>Try your AI</Title>
      <Subtitle>
        This is trying a live version of your AI and will only work with
        published flows
      </Subtitle>
      <Box>
        <DescriptionTitle>
          As you are trying your AI 3 things will be happening
        </DescriptionTitle>
        <DescriptionContainer>
          {descriptions.map((description, index) => (
            <>
              <Description key={index}>
                <CenteredImg src={description.img} />
                <ul>
                  {description.texts.map(t => (
                    <li key={t}>{t}</li>
                  ))}
                </ul>
              </Description>
              {index < descriptions.length - 1 && <Divider key={index} />}
            </>
          ))}
        </DescriptionContainer>
      </Box>
      <ButtonContainer>
        <Button color="red" onClick={modalProps.onOk}>
          Got it
        </Button>
      </ButtonContainer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 24px 50px 30px;
  }
`;

const Title = styled.h4`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
  margin-top: 5px;
`;

const Subtitle = styled.p`
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
`;

const Box = styled.div`
  background: #eaf5f4;
  border-radius: 18px;
  padding: 20px 20px 30px 32px;
`;

const DescriptionTitle = styled.h5`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 18px;
  text-align: center;
`;

const DescriptionContainer = styled.div`
  display: flex;
`;
const Description = styled.div`
  flex: 1;

  > ul {
    list-style-type: none;
    margin-top: 10px;
    margin-bottom: 0px;
    padding-inline-start: 0px;

    > li {
      font-size: 12px;
      position: relative;
      padding-left: 3px;
      margin-bottom: 7px;
      color: ${colors.DARK_BLUE};

      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: -9px;
        width: 9px;
        height: 9px;
        background-image: url(${customBullet});
        background-size: cover;
        transform: translateY(-50%);
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 30px;

  > button {
    margin: auto;
  }
`;

const Divider = styled.div`
  height: auto;
  margin-top: 53px;
  width: 1px;
  background: #06233d1c;
  margin-left: 20px;
  margin-right: 30px;
`;

const CenteredImg = styled.img`
  margin: auto;
  display: flex;
`;

export default TryMyAiModal;
