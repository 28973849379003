import { useState } from 'react';
import AutoGenJob, { AutoGenJobProps, AutogenStatus } from '../AutoGenJob';
import { UseQueryResult } from '@tanstack/react-query';
import { useSelectedClient } from './authentication';

// eslint-disable-next-line no-shadow
export enum StatusType {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED'
}
const stoppedStates: AutogenStatus[] = ['COMPLETE', 'FAILED'];
const runningStates: AutogenStatus[] = ['PENDING', 'PROCESSING'];
const interval = process.env.AUTOGEN_INTERVAL
  ? parseInt(process.env.AUTOGEN_INTERVAL)
  : 60000; // in milliseconds

export function isRunningStatus(status: AutogenStatus): boolean {
  return runningStates.includes(status);
}

const useAutoGen = (
  refetchInterval = interval
): UseQueryResult<AutoGenJobProps> => {
  const { selectedClient } = useSelectedClient() as { selectedClient: string };
  const [shouldStartPolling, setShouldStartPolling] = useState(true);

  const autogen = AutoGenJob.useGet(
    '',
    {},
    {
      refetchIntervalInBackground: true,
      enabled: shouldStartPolling,
      refetchInterval: data => {
        if (
          (data &&
            stoppedStates.includes(data.status) &&
            selectedClient === data.client) ||
          data === null
        ) {
          return false;
        } else {
          return refetchInterval;
        }
      },
      onSuccess: data => {
        if (data && !isRunningStatus(data.status)) {
          setShouldStartPolling(false);
        } else if (
          data &&
          !shouldStartPolling &&
          isRunningStatus(data.status)
        ) {
          setShouldStartPolling(true);
        }
      }
    }
  );

  return autogen;
};

export default useAutoGen;
