import PropTypes from 'prop-types';
import React, { useContext } from 'react';

const SettingsContext = React.createContext({});

export default function useSettings() {
  return useContext(SettingsContext);
}

export function SettingsProvider({ children, value }) {
  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object
};
