import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef
} from 'react';
import PropTypes from 'prop-types';

import isReadyForCodeExchange from './isReadyForCodeExchange';
import startAuthorizationCodeFlow from './startAuthorizationCodeFlow';
import tryGetUser from './tryGetUser';
import exchangeCodeForAccessToken from './exchangeCodeForAccessToken';
import logout from './logout';
import { useNavigate, useLocation } from 'react-router-dom';
import exchangeRefreshTokenForAccessToken from './exchangeRefreshTokenForAccessToken';

import { logger } from '../../../utils/logger';

const Authentication = createContext();

export const useAuthentication = () => useContext(Authentication);
export function AuthenticationProvider({
  settings: { authURL, loginURL, clientID },
  loadingFallback,
  children
}) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const tokenIntervalCheck = 10 * 60000; // x mins interval
  const [user, setUser] = useState({});
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const isTokenExchangeInProgress = useRef(false);

  const refreshToken = useCallback(async () => {
    const redirectUri = new URLSearchParams(search).get('redirect_uri');

    isTokenExchangeInProgress.current = true;

    const { access_token: newAccessToken, expires_in: exp } =
      await exchangeRefreshTokenForAccessToken(authURL, clientID);
    const maybeUser = await tryGetUser(authURL, newAccessToken);
    if (!maybeUser) {
      logger(
        'newAccessToken has no authorization'
      );

      startAuthorizationCodeFlow(loginURL, clientID, redirectUri);
      return;
    }

    setToken({ value: newAccessToken });
    setUser(maybeUser);
    isTokenExchangeInProgress.current = false;

    return { token: newAccessToken, exp, user: maybeUser };
  }, [authURL, clientID, loginURL, search]);

  const authenticationState = {
    user,
    token: token?.value ?? null,
    expired: token?.expired ?? false,
    isLoading,
    logout: () => logout(authURL, clientID, token),
    clearToken: useCallback(
      () => setToken(currentToken => ({ ...currentToken, expired: true })),
      [setToken]
    ),
    refreshToken
  };

  useEffect(() => {
    let checkTokenInterval = null;

    bootstrapAuthentication().catch(() =>
      logger(
        'Could not connect to login server. Please try again later.'
      )
    );
    if (user.status !== 'offline') {
      checkTokenInterval = setInterval(async () => {
        logger(
          'Token Interval check'
        );
        bootstrapAuthentication();
      }, tokenIntervalCheck);
    } else {
      clearInterval(checkTokenInterval);
    }

    async function bootstrapAuthentication() {
      if (isTokenExchangeInProgress.current) {
        return;
      }

      if (isReadyForCodeExchange()) {
        await exchangeCodeForAccessToken(authURL, clientID);
        navigate('/');
      }

      if (!token || token.expired) {
        const tokenResult = await refreshToken();

        if (!tokenResult?.user) {
          return;
        }
      }

      setIsLoading(false);
      return () => {
        clearInterval(checkTokenInterval);
      };
    }
  }, [authURL, clientID, navigate, token, refreshToken, tokenIntervalCheck, user.status]);

  return (
    <Authentication.Provider value={authenticationState}>
      {isLoading ? loadingFallback : children}
    </Authentication.Provider>
  );
}

AuthenticationProvider.propTypes = {
  settings: PropTypes.shape({
    authURL: PropTypes.string.isRequired,
    loginURL: PropTypes.string.isRequired,
    clientID: PropTypes.string.isRequired
  }),
  loadingFallback: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};
