import React from 'react';
export default function ConversationViewMenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="14.035"
      height="16.156"
      viewBox="0 0 14.035 16.156">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_15290" data-name="Rectangle 15290" width="14.035" height="16.156" fill="#ffffff" />
        </clipPath>
      </defs>
      <g id="Group_13886" data-name="Group 13886" transform="translate(11220.173 6749.534)">
        <g id="Group_13887" data-name="Group 13887" transform="translate(-11220.173 -6749.534)">
          <g id="Group_13886-2" data-name="Group 13886" clipPath="url(#clip-path)">
            <path id="Path_33501" data-name="Path 33501" d="M145.917,217.559a.66.66,0,1,1-.66-.66.66.66,0,0,1,.66.66" transform="translate(-138.24 -207.362)" fill="#ffffff" />
            <path id="Path_33502" data-name="Path 33502" d="M106.859,72.3a2.424,2.424,0,0,0-2.425,2.425h0a.66.66,0,1,0,1.319,0,1.107,1.107,0,1,1,1.107,1.107.66.66,0,0,0-.66.66V77.2a.66.66,0,1,0,1.319,0v-.138a2.426,2.426,0,0,0-.66-4.76" transform="translate(-99.842 -69.122)" fill="#ffffff" />
            <path id="Path_33503" data-name="Path 33503" d="M11.257,0H2.779A2.777,2.777,0,0,0,0,2.779V15.5a.66.66,0,0,0,1.055.528l2.65-1.987h7.551a2.777,2.777,0,0,0,2.779-2.779V2.779A2.777,2.777,0,0,0,11.257,0m1.46,11.257a1.459,1.459,0,0,1-1.46,1.46H3.485a.66.66,0,0,0-.4.132l-1.77,1.328V2.779a1.459,1.459,0,0,1,1.46-1.46h8.478a1.459,1.459,0,0,1,1.46,1.46Z" fill="#ffffff" />
          </g>
        </g>
      </g>
    </svg>
  );
}
