import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import Button from './presentational/Button';
import { ReactComponent as Logo } from './assets/header-logo.svg';

export default function Header() {
  return (
    <HeaderWrapper>
      <Logo />
      <a href="/">
        <ResponsiveButton color="red" kind="filled">
          Sign in to AI studio
        </ResponsiveButton>
      </a>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 2;
  background-color: #ffffff;

  @media (max-width: 425px) {
    box-shadow: unset;

    svg {
      width: 125px;
      height: auto;
    }
  }
`;

const ResponsiveButton = styled(Button)`
  padding: 16px 20px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 425px) {
    padding: 12px 20px;
  }
`;
