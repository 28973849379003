async function get(billingURL, selectedClient, token) {
  return await fetch(
    `${billingURL}/billing/clients/${selectedClient}/pointAccount`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  );
}

export default async function getBalance(
  billingURL,
  selectedClient,
  token,
  message
) {
  try {
    const response = await get(billingURL, selectedClient, token);

    if (response.ok) {
      const { balance } = await response.json();
      return balance;
    }

    const error = await response.json();
    message.error(error.message);
  } catch (e) {
    message.error('Error connecting to server');
  }
}
