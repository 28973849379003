import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants';
import Button from '../../../modules/flows/presentational/Button';
import { ReactComponent as RightArrow } from '../../assets/right-arrow.svg';

type Props = {
  title: string;
  description: string;
  url: string;
};

const PluginBlock = ({ title, description, url }: Props) => {
  const navigateToPlugin = () => {
    window.open(url, '_blank');
  };

  return (
    <Container>
      <div>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </div>
      <Button onClick={navigateToPlugin}>
        <ButtonContainer>
          View plugin
          <RightArrow />
        </ButtonContainer>
      </Button>
    </Container>
  );
};

export default PluginBlock;

const Title = styled.h4`
  font-weight: bold;
  font-size: 16px;
  color: ${colors.DARK_BLUE};
  margin-bottom: 8px;
`;

const Description = styled.p`
  color: ${colors.DARK_BLUE};
  font-size: 16px;
  margin: 0px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const ButtonContainer = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0px 8px;
`;
