async function remove(billingURL, selectedClient, token, reasons) {
  const params = new URLSearchParams();
  params.append('reasons', reasons);

  return await fetch(
    `${billingURL}/billing/clients/${selectedClient}/licence?${params.toString()}`,
    {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  );
}

export default async function deleteAccount(
  billingURL,
  selectedClient,
  token,
  reasons
) {
  const response = await remove(billingURL, selectedClient, token, reasons);

  if (response.ok) {
    return await response.json();
  }

  throw new Error('Error encountered');
}
