import React from 'react';
import styled from 'styled-components';

export default function ChevronDownIcon() {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="10.601"
      height="6.275"
      viewBox="0 0 10.601 6.275"
      style={{ fill: 'none', strokeWidth: '1.5px' }}
    >
      <path
        className="a"
        d="M298.436,55.013l4.593-4.679,4.958,4.679"
        transform="translate(308.501 55.559) rotate(180)"
      />
    </StyledSVG>
  );
}

const StyledSVG = styled.svg`
  width: 10px;
  margin-left: 8px;
`;
