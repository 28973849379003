import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useAuthentication } from './provider';
import { useSelectedClient } from './ClientProvider';
import API from '../../index';

import { logger } from '../../../utils/logger';

const UserStatus = createContext({
  userStatus: 'offline',
  setUserStatus: () => {}
});

export const useUserStatus = () => useContext(UserStatus);

export function UserStatusProvider({ children }) {
  const { user, token } = useAuthentication();
  const { selectedClient } = useSelectedClient();
  const [userStatus, setUserStatus] = useState(null);

  useEffect(() => {
    if (user.status) {
      setUserStatus(user.status);
    }
  }, [user]);

  const setUserStatusAndStore = useCallback(
    async newUserStatus => {
      const response = await API.updateAgentStatus(
        token,
        user.id,
        selectedClient,
        { status: newUserStatus }
      );

      if (response) {
        setUserStatus(response.status);
        logger('Status updated');
      } else {
        logger('Your status was not updated');
      }
    },
    [token, user, selectedClient]
  );

  return (
    <UserStatus.Provider
      value={{
        userStatus: userStatus || 'offline',
        setUserStatus: setUserStatusAndStore
      }}
    >
      {children}
    </UserStatus.Provider>
  );
}

UserStatusProvider.propTypes = {
  children: PropTypes.node.isRequired
};
