import React, { Component } from 'react';
import PropTypes from 'prop-types';
import routes from './routes';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined
    };
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const { error } = this.state;

    if (error) {
      if (error.status) {
        switch (error.status) {
          case 403:
            return <routes.NotAuthorized />;
          case 404:
            return <routes.NotFound />;
          case 408:
            return <routes.NoInternet />;
          default:
            return <routes.ServerError />;
        }
      }

      return <routes.ServerError />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  history: PropTypes.object
};

export default ErrorBoundary;
