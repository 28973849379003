import { useState, useEffect } from 'react';
import { logger } from '../../utils';

type Document = {
  mozHidden: string;
  msHidden: string;
  webkitHidden: string;
};

const useWindowVisible = (): boolean => {
  const [visible, setVisible] = useState<boolean>(true);

  useEffect(() => {
    let hidden: string | undefined,
      state: string | undefined,
      visibilityChange: string | undefined;

    if (typeof document.hidden !== 'undefined') {
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
      state = 'visibilityState';
    } else if (
      typeof (document as unknown as Document).mozHidden !== 'undefined'
    ) {
      hidden = 'mozHidden';
      visibilityChange = 'mozvisibilitychange';
      state = 'mozVisibilityState';
    } else if (
      typeof (document as unknown as Document).msHidden !== 'undefined'
    ) {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
      state = 'msVisibilityState';
    } else if (
      typeof (document as unknown as Document).webkitHidden !== 'undefined'
    ) {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
      state = 'webkitVisibilityState';
    }

    const handleVisibityChange = () => {
      if (state) {
        const value = (document as unknown as Record<string, string>)[state];
        setVisible(value !== hidden);
      }
    };

    logger('handleVisibilityChange', visibilityChange, state);
    if (visibilityChange && state) {
      document.addEventListener(visibilityChange, handleVisibityChange);
    }

    return () => {
      if (visibilityChange && state) {
        document.removeEventListener(visibilityChange, handleVisibityChange);
      }
    };
  }, []);

  return visible;
};

export default useWindowVisible;
