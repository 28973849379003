import { Checkbox, Form, Input, Modal, ModalProps, Row, message } from 'antd';
import React, { useCallback, useState, useEffect } from 'react';
import Button from '../../../modules/flows/presentational/Button';
import billingApi from '../../../billing-api';
import {
  useAuthentication,
  useSelectedClient
} from '../../../uni-comms-api/hooks/authentication';
import deleteAccountIcon from '../../assets/delete-account.svg';
import styled from 'styled-components';
import { colors } from '../../../constants';

import { logger } from '../../../utils/logger';

const termsOfServiceLink = 'https://ebi.ai/customer-terms-and-conditions/';
const privacyPolicyLink = 'https://ebi.ai/privacy-policy/';
const ebiAiLink = 'https://ebi.ai/';

interface Props extends Omit<ModalProps, 'onOk'> {
  onOk?: () => void;
}

const illegalSymbols = Array.from('<>{}');

export default function DeleteAccountModal({
  onOk,
  ...modalProps
}: Props): JSX.Element {
  const [form] = Form.useForm();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [validationError, setValidationError] = useState(false);
  const { selectedClient } = useSelectedClient();
  const { token } = useAuthentication();
  const checkbox = Form.useWatch('reason', form) as string[] | undefined;
  const others = Form.useWatch('others', form) as string | undefined;

  function otherFieldValidator(_: unknown, value: string) {
    const illegalSymbolsPattern = new RegExp(illegalSymbols.join('|'));
    if (illegalSymbolsPattern.test(value)) {
      // setValidationError(true);
      return Promise.reject();
    }
    return Promise.resolve();
  }

  useEffect(() => {
    async function check() {
      try {
        await form.validateFields(['others']);
        setValidationError(false);
      } catch (error) {
        setValidationError(true);
      }
    }

    void check();
  }, [others, form, setValidationError]);

  const reasonsOptions = [
    'I never went live',
    'I changed my mind',
    'I chose a different supplier',
    'I had technical issues',
    "I don't need an AI Assistant"
  ];

  const handleDeleteAccount = useCallback(async () => {
    setLoading(true);
    const formValues: Record<string, string | string[]> =
      form.getFieldsValue() as Record<string, string>;
    const reasons = Object.values(formValues)
      .flat()
      .filter(d => d)
      .join(',');

    try {
      await billingApi.deleteAccount(
        process.env.BILLING_URL,
        selectedClient,
        token,
        reasons
      );
      onOk?.();
      await messageApi.open({
        type: 'success',
        content:
          'Thank you, a member of the team will be in touch to confirm your cancellation'
      });
    } catch (error) {
      logger(error);
    } finally {
      setLoading(false);
    }
  }, [selectedClient, token, messageApi, form, onOk]);

  return (
    <>
      {messageContextHolder}
      <Modal {...modalProps} footer={null} width={588} destroyOnClose>
        <ImageContainer>
          <img src={deleteAccountIcon} />
        </ImageContainer>
        <Title>You&apos;ve requested we delete your account</Title>
        <Text>
          Once you confirm your account will be permanently deleted and we will
          not be able to restore it for you.
        </Text>
        <Form form={form} layout="vertical" preserve={false}>
          <Form.Item
            name="reason"
            label={
              <Label>
                We’re sorry you’ve decided to leave, please provide feedback so
                we can continue to improve our service:
              </Label>
            }
          >
            <Checkbox.Group>
              {reasonsOptions.map(r => (
                <Row key={r}>
                  <Checkbox value={r}>{r}</Checkbox>
                </Row>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            name="others"
            rules={[
              {
                required: false
              },
              {
                validator: otherFieldValidator,
                message: 'Please only use plain text to describe your reasons'
              }
            ]}
          >
            <Input.TextArea
              placeholder="Others - please specify"
              rows={3}
              style={{ resize: 'none' }}
            />
          </Form.Item>
        </Form>
        <FooterText>
          Please note that your account and associated data will be deleted in
          accordance with our{' '}
          <Link target="_blank" href={termsOfServiceLink}>
            terms of service
          </Link>
          , as well as our{' '}
          <Link target="_blank" href={privacyPolicyLink}>
            privacy policy
          </Link>
          .
          <br />
          <br />
          Thanks for supporting{' '}
          <Link target="_blank" href={ebiAiLink}>
            EBI.AI
          </Link>
        </FooterText>

        <FooterContainer>
          <Button kind="standard" color="red" onClick={modalProps.onCancel}>
            Go back
          </Button>
          <Button
            isLoading={loading}
            kind="filled"
            color="red"
            onClick={() => void handleDeleteAccount()}
            disabled={(!checkbox?.length && !others) || validationError}
          >
            Confirm
          </Button>
        </FooterContainer>
      </Modal>
    </>
  );
}

const ImageContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const Title = styled.p`
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-size: 16px;
`;

const Label = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 10px;
`;

const FooterText = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

const Link = styled.a`
  font-size: 14px;
  font-weight: 700;
  color: ${colors.RED};
`;

const FooterContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;
