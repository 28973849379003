import { useEffect, useState, useCallback } from 'react';
import { logger } from '../../utils';
import API from '../../modules/myAI/settings/api';
import {
  useSelectedBot,
  useSelectedClient,
  useAuthentication
} from '../../shell';

interface Agent {
  _id: string;
  type: 'bot' | 'team' | 'app';
  bot?: string;
  team?: string;
  app?: string;
}

interface PipelineState {
  pipeline: Partial<{
    _id: string;
    channels: Array<{ config: { theme: string } }>;
    otherAgents: Record<string, Agent>;
  }>;
  loading: boolean;
}

export function usePipeline(): PipelineState & {
  update: (
    pipeline: string,
    botName: string,
    botAvatar: string
  ) => Promise<void>;
} {
  const { token } = useAuthentication();
  const { selectedClient } = useSelectedClient();
  const { selectedBot } = useSelectedBot();

  const [state, setState] = useState<PipelineState>({
    loading: true,
    pipeline: { channels: [{ config: { theme: '' } }] }
  });

  useEffect(() => {
    async function run() {
      if (!selectedBot.pipelineId) {
        setState({
          loading: false,
          pipeline: {}
        });
        return;
      }

      const pipeline = (await API.getPipeline(
        process.env.AUTH_URL,
        selectedClient,
        token,
        selectedBot.pipelineId
      )) as PipelineState['pipeline'];

      setState({
        loading: false,
        pipeline
      });
    }

    run().catch(err => {
      logger(err);
    });
  }, [selectedBot.pipelineId, token, selectedClient]);

  const update = useCallback(
    async (newPipeline, botName, botAvatar) => {
      const { pipeline } = (await API.postUpdateTheme(
        process.env.AUTH_URL,
        selectedClient,
        selectedBot._id,
        token,
        botName,
        botAvatar,
        newPipeline,
        state.pipeline?._id
      )) as { pipeline: PipelineState['pipeline'] };
      setState({ pipeline, loading: false });
    },
    [selectedBot, selectedClient, token, state.pipeline]
  );

  return {
    ...state,
    update
  };
}
