import { useEffect, useState } from 'react';
import { getInitials } from '../../utils';
import {
  useSelectedClient,
  useFetch
} from '../../uni-comms-api/hooks/authentication';

export default () => {
  const { selectedClient } = useSelectedClient();
  const [teams, setTeams] = useState([]);

  const { data, loading, error, refresh } = useFetch(
    `${process.env.AUTH_URL}/clients/${selectedClient}/teams`
  );

  useEffect(() => {
    setTeams(
      data && data.items.length
        ? data.items.map(team => ({
            ...team,
            initials: getInitials(team.name)
          }))
        : []
    );
  }, [data]);

  return { teams, loading, error, refresh };
};
