import React, { useCallback, useEffect, useState } from 'react';
import useBots from './useBots';
import useTeams from './useTeams';
import useApps from './useApps';
import { Select } from 'antd';

export default () => {
  const { bots, error: botsError } = useBots();
  const { teams, error: teamsError } = useTeams();
  const { apps, error: appsError } = useApps();
  const [agents, setAgents] = useState({});
  const [error, setError] = useState();

  const getOptions = useCallback(
    (data, type) =>
      data.map(agent => (
        <Select.Option
          key={`${type}-${agent._id}`}
          type={type}
          value={agent._id}
        >
          {agent.name}
        </Select.Option>
      )),
    []
  );

  useEffect(() => {
    setError(botsError || teamsError || appsError);
  }, [botsError, teamsError, appsError]);

  useEffect(() => {
    setAgents({
      bot: {
        label: 'Bot',
        values: bots,
        options: getOptions(bots, 'bot')
      },
      team: {
        label: 'Team',
        values: teams,
        options: getOptions(teams, 'team')
      },
      app: {
        label: 'App',
        values: apps,
        options: getOptions(apps, 'app')
      }
    });
  }, [bots, teams, apps, getOptions]);

  return {
    error,
    agents
  };
};
