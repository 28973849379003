import { useEffect, useState } from 'react';
import {
  useSelectedClient,
  useFetch,
  useAuthentication
} from './authentication';

interface LoginAttempt {
  user_id: string;
  datetime: string;
  ip_address: string;
  successful: boolean;
}

export default (datetime: Date): { attempt: LoginAttempt | null } => {
  const { selectedClient } = useSelectedClient();
  const { user } = useAuthentication();

  const [attempt, setAttempt] = useState<LoginAttempt | null>(null);

  const { data } = useFetch<LoginAttempt>(
    `${process.env.AUTH_URL ?? ''}/clients/${selectedClient}/user/${
      user.id
    }/loginAttempt?datetime=${datetime.toISOString()}`
  );

  useEffect(() => {
    if (data) {
      setAttempt(data);
    }
  }, [data]);

  return { attempt };
};
