import { useEffect, useState } from 'react';
import { getInitials } from '../../utils';
import {
  useSelectedClient,
  useFetch
} from './authentication';

export default memberId => {
  const { selectedClient } = useSelectedClient();
  const [member, setMember] = useState(null);

  const fetchURL =
    memberId === 'me'
      ? `${process.env.AUTH_URL}/clients/${selectedClient}/users/me`
      : `${process.env.AUTH_URL}/clients/${selectedClient}/user/${memberId}`;

  const { data: newMember, loading, error } = useFetch(fetchURL);

  useEffect(() => {
    setMember(
      newMember
        ? {
          ...newMember,
          ...(newMember._id && { id: newMember._id }),
          initials: getInitials(newMember.fullName)
        }
        : null
    );
  }, [newMember]);

  return { member, loading, error };
};
