import React, {
  useEffect,
  createContext,
  useContext,
  useReducer,
  useState,
  useMemo,
  useCallback
} from 'react';
import PropTypes, { string } from 'prop-types';
import reducer, { initialState, setLoading, setReady } from './reducer';
import {
  useAuthentication,
  useSelectedClient
} from '../../uni-comms-api/hooks/authentication';
import injectClawson from './injectClawson';
import useMember from '../../uni-comms-api/hooks/useMember';
import useClients from '../header/hooks/useClients';

export const Clawson = createContext();

export function useClawson(options = {}) {
  const { member } = useMember('me');
  const { clients } = useClients();
  const { selectedClient } = useSelectedClient();
  const client = clients.find(item => item.id === selectedClient);
  const {
    ready,
    loading,
    messenger,
    open,
    setOpen,
    mountedTargets,
    setMountedTargets
  } = useContext(Clawson);

  const { token } = useAuthentication();
  const shouldLoad = options.loadWhen !== undefined ? options.loadWhen : true;

  delete options.loadWhen;

  const mounted = mountedTargets.has(options.embed.target);

  useEffect(() => {
    if (!shouldLoad && mounted) {
      setMountedTargets(currentlyMounted => {
        const removedMounted = new Set(currentlyMounted);
        removedMounted.delete(options?.embed?.target);
        return removedMounted;
      });
    }
  }, [shouldLoad, mountedTargets, options, setMountedTargets, mounted]);

  const initialize = useCallback(() => {
    if (member && messenger.client && messenger.pipeline && client) {
      const embedTarget = options?.embed?.target;
      const isTargetMounted = mountedTargets.has(embedTarget);

      if (!isTargetMounted || !embedTarget) {
        window.clawsonChatBot.init({
          immediateLoad: true,
          client: messenger.client,
          pipeline: messenger.pipeline,
          metadata: {},
          authentication: {
            identity: {
              email: member.email,
              firstName: member.firstName,
              fullName: member.fullName,
              lastName: member.lastName,
              clientName: client.name
            },
            access: token
          },
          ...options
        });
      }

      if (embedTarget) {
        const newSet = new Set(mountedTargets);
        newSet.add(embedTarget);
        setMountedTargets(newSet);
      }
    }
  }, [
    member,
    client,
    options,
    token,
    messenger,
    mountedTargets,
    setMountedTargets
  ]);

  useEffect(() => {
    if (shouldLoad && ready && token && member && client && !mounted) {
      initialize();
    }
  }, [
    messenger,
    options,
    ready,
    shouldLoad,
    token,
    member,
    client,
    open,
    mounted,
    initialize
  ]);

  return {
    ready,
    loading,
    mounted: !!mountedTargets.size,
    open,
    setOpen,
    initialize
  };
}

export function ClawsonProvider({ messenger, children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [open, setOpen] = useState(false);
  const [mountedTargets, setMountedTargets] = useState(new Set());

  useEffect(() => {
    if (!state.ready || !window.clawsonChatBot) {
      const staticJs =
        process.env.NODE_ENV === 'development'
          ? '/static/js/bundle.js'
          : '/static/js/main.js';

      injectClawson(messenger.url + staticJs).then(() => {
        dispatch(setReady(true));
        dispatch(setLoading(false));
      });
    }
  }, [messenger, state.ready]);

  const value = useMemo(
    () => ({
      loading: state.loading,
      ready: state.ready,
      messenger,
      open,
      setOpen,
      mountedTargets,
      setMountedTargets
    }),
    [
      state.loading,
      state.ready,
      messenger,
      open,
      setOpen,
      mountedTargets,
      setMountedTargets
    ]
  );

  return <Clawson.Provider value={value}>{children}</Clawson.Provider>;
}

ClawsonProvider.propTypes = {
  messenger: PropTypes.shape({
    url: string.isRequired,
    client: string.isRequired,
    pipeline: string.isRequired
  }).isRequired,
  children: PropTypes.node.isRequired
};
