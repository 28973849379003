import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants';

type Props = {
  steps: Array<{
    header?: string;
    content: ReactNode | ReactNode[];
  }>;
};

const STEP_COLORS = [
  {
    background: '#EA555F40',
    text: '#EA555F'
  },
  {
    background: '#00908140',
    text: '#009081'
  },
  {
    background: '#06233D40',
    text: '#06233D'
  },
  {
    background: '#FFC10740',
    text: '#FFC107'
  },
  {
    background: '#2A7DBC40',
    text: '#2A7DBC'
  }
];

const StepBuilder = ({ steps }: Props) => {
  return (
    <Container>
      {steps.map(({ header, content }, index) => (
        <div key={index}>
          {!!header && (
            <StepTitleContainer>
              <StepTitle index={index}>Step {index + 1}</StepTitle>
              {header}
            </StepTitleContainer>
          )}
          <Content>{content}</Content>
        </div>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StepTitleContainer = styled.span`
  color: ${colors.DARK_BLUE};
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 4px;
`;

const StepTitle = styled.span<{ index: number }>`
  background-color: ${({ index }) =>
    STEP_COLORS[index % STEP_COLORS.length].background};
  color: ${({ index }) => STEP_COLORS[index % STEP_COLORS.length].text};
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  padding: 4px 12px;
  margin-right: 8px;
`;

const Content = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default StepBuilder;
