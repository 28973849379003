import { generateState } from './state';

export default function startAuthorizationCodeFlow(
  loginURL,
  clientID,
  redirectUri
) {
  const origin = new URL(window.location.href).origin;
  const state = generateState();

  let redirectUrlSearchParams;
  let redirectUrl;

  try {
    redirectUrl = new URL(redirectUri);
    redirectUrlSearchParams = Object.fromEntries(
      redirectUrl.searchParams.entries()
    );
  } catch {
    redirectUrlSearchParams = {};
  }

  const query = new URLSearchParams({
    ...redirectUrlSearchParams,
    state,
    client_id: clientID,
    response_type: 'code',
    redirect_uri: origin
  }).toString();

  const finalRedirect =
    redirectUrl && redirectUrl.origin
      ? `${redirectUrl.origin}${redirectUrl.pathname}?${query}`
      : `${loginURL}/oauth/?${query}`;

  window.location.href = finalRedirect;
}
