import { FlowTemplatesProps } from './FlowTemplates';
import {
  buildUseCreateHook,
  buildUseDeleteHook,
  buildUseGetHook,
  buildUseListHook,
  buildUsePatchHook
} from './genericHooks';
import { Intent } from './Intents';

const BOT_URL = process.env.BOT_URL || '';
const REPORTING_URL = process.env.REPORTING_URL || '';

// Some bug in the eslint-typescript that thinks this enum is shadowing?
// eslint-disable-next-line no-shadow
export enum MessageType {
  Text = 'text',
  Generic = 'generic',
  List = 'list',
  Button = 'button'
}
// eslint-disable-next-line no-shadow
export enum OperationLabels {
  EQ = '=',
  GT = '>',
  LT = '<',
  NEQ = '!==',
  CONTAINS = 'contains',
  IS = 'is',
  NOT = 'not'
}
// eslint-disable-next-line no-shadow
export enum Operator {
  AND = 'and',
  OR = 'or',
  NOT = 'not',
  NOR = 'nor',
  XOR = 'xor',
  NAND = 'nand',
  XNOR = 'xnor'
}

export interface NodeVariables {
  inputVariables?: Variable[];
  outputVariables?: Variable[];
  outputVariable: Variable;
}

interface BaseVariationValue {
  type: MessageType;
  value: unknown;
}

export interface ButtonVariationValue extends BaseVariationValue {
  type: MessageType.Button;
  value: {
    text: string;
    buttons: {
      type: 'web_url';
      title: string;
      url: string;
    }[];
  };
}

export interface GenericVariationValue extends BaseVariationValue {
  type: MessageType.Generic;
  value: {
    title: string;
    subtitle: string;
    buttons: {
      type: 'web_url';
      title: string;
      url: string;
    }[];
  }[];
}

export interface TextVariationValue extends BaseVariationValue {
  type: MessageType.Text;
  value: string;
}

export type VariationValue =
  | TextVariationValue
  | GenericVariationValue
  | ButtonVariationValue;

interface ResponseNode {
  _id?: string;
  type: 'response';
  response: {
    _id?: string;
    customisable?: boolean;
    quick_replies?: boolean;
    history?: [];
    bot_id?: string;
    created?: string;
    updated?: string;
    variations: {
      _id?: string;
      tags: Record<string, string>;
      value: VariationValue[];
    }[];
  };
  inputVariables?: Array<Variable>;
}

interface ClosedEntity {
  _id?: string;
  type: 'closed';
  values: {
    _id?: string;
    value: string;
    synonyms: string[];
  }[];
}

interface SystemEntity {
  _id?: string;
  type: 'system';
  name: string;
}

type Entity = ClosedEntity | SystemEntity;

interface Slot {
  _id?: string;
  entity: Entity;
  exitEntities: Entity[];
  question: {
    response: ResponseNode['response'];
  };
}

export interface MultipleChoiceNode {
  _id?: string;
  type: 'multiple-choice';
  slot: Slot;
  answers: {
    _id?: string;
    values: string[];
    nodes: Node[];
  }[];
  inputVariables?: Array<Variable>;
}

export interface LivechatNode {
  _id?: string;
  type: 'livechat';
  livechat: {
    variations: [
      {
        tags: Record<string, string>;
        value: [
          {
            etype: 'handover';
            party: string;
            target: 'livechat';
          }
        ];
      }
    ];
  };
}

export interface Variable {
  uuid: string;
  type?: string;
  name: string;
  testValue?: unknown;
}

export interface QuestionNode {
  _id?: string;
  type: 'question';
  name: string;
  question: {
    value: string;
    type: string;
    options?: Record<string, string>;
  };
  inputVariables?: Variable[];
  outputVariable?: Variable;
  acceptOtherResponses?: boolean;
}

export interface ActionNode {
  _id?: string;
  type: 'action';
  name: string;
  action: {
    installation: string;
    operationId: string;
    parameterMappings: Record<string, unknown>;
  };
  inputVariables?: Variable[];
  outputVariable?: Variable;
}

export interface SetGlobalVariableNode {
  _id?: string;
  type: 'set-variable';
  'set-variable': {
    value: string;
    output: Variable;
  };
  inputVariables: Variable[];
}

export interface LogicalOperator {
  index: number;
  label: string;
  method: Operator;
}
export interface ConditionalProps {
  conditions: [
    {
      values: string[] | number[];
      operation: {
        label: string;
        method: OperationLabels;
      };
    }
  ];
  index: number;
  logicalOperators?: LogicalOperator[];
}

export interface LinkFlowNode {
  _id?: string;
  type: 'link-flow';
  flow: {
    _id: string;
  };
}

export interface UploadFileNode {
  _id?: string;
  type: 'file-upload';
  fileUpload: {
    accept?: string;
    maxMbSize?: number;
    multiple?: boolean;
  };
  inputVariables?: Variable[];
  outputVariable?: Variable;
}

export type Node =
  | ResponseNode
  | MultipleChoiceNode
  | QuestionNode
  | ActionNode
  | DecisionNode
  | LivechatNode
  | LinkFlowNode
  | SetGlobalVariableNode
  | UploadFileNode
  | LoopNode;
export interface DecisionNode {
  _id?: string;
  type: 'decision';
  name: string;
  decision: {
    branches: [
      {
        conditional: ConditionalProps[];
        nodesToInsert: Node[];
        insertNodesFromFlowId?: string;
      }
    ];
  };
  inputVariables?: Variable[];
  outputVariable?: Variable;
}

export interface LoopNode {
  _id?: string;
  type: 'loop';
  name: string;
  loop: {
    branches: [
      {
        conditional: ConditionalProps[];
        nodesToInsert: Node[];
        insertNodesFromFlowId?: string;
      }
    ];
  };
  inputVariables?: Variable[];
  outputVariable?: Variable;
}

export interface Flow {
  _id: string;
  assistant: string;
  trigger: {
    intent?: string | Intent;
  };
  nodes: Node[];
  variables: Record<string, Variable>;
}

interface GetFlowParams {
  expand?: ('intent' | 'response')[];
}

interface CreateFlowParams {
  assistant: string;
  trigger: {
    intent: string;
  };
  config?: {
    excludeFromWelcome: boolean;
  };
  nodes?: Node[];
  autoGenerateResponse?: boolean;
  utterance?: string;
  skipSimilar?: boolean;
}

export interface CreateFlowFromTemplateParams {
  assistant: string;
  skipSimilar?: boolean;
  template: Partial<FlowTemplatesProps>;
  triggeringUtterance?: string;
}

export interface PatchFlowParams {
  assistant: string;
  trigger?: {
    intent?: string;
  };
  config?: { excludeFromWelcome: boolean };
  nodes?: Node[];
}

interface ListFlowParams {
  assistant: string;
  filters: ('published' | 'draft')[];
  trigger: 'intent' | 'system';
  page?: number;
  search?: string;
  excludeClasses?: 'small-talk'[];
  refetchInterval?: number;
  limit?: number;
  threshold?: number;
  similarFlows?: boolean;
}

const flowsPath = `${BOT_URL}/nlu/flows`;
const reportPath = `${REPORTING_URL}/reporting/clients`;
export default {
  useList: buildUseListHook<Flow, ListFlowParams>('flow', flowsPath),
  useGet: buildUseGetHook<Flow, GetFlowParams>('flow', flowsPath),
  useCreate: buildUseCreateHook<Flow, CreateFlowParams>('flow', flowsPath),
  usePatch: buildUsePatchHook<Flow, PatchFlowParams>('flow', flowsPath),
  useDelete: buildUseDeleteHook<Flow>('flow', flowsPath),
  useGetFlowsCount: buildUseGetHook<Flow, GetFlowParams>('flow', reportPath),
  useCreateFlowFromTemplate: buildUseCreateHook<
    Flow & {
      status: 200 | 201;
    },
    CreateFlowFromTemplateParams
  >('flowTemplates', `${flowsPath}/template`)
};
