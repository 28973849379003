async function get(authUrl, flowId, params, token, clientId) {
  const query = params ? new URLSearchParams(params).toString() : '';

  return await fetch(`${authUrl}/nlu/flowVersions/${flowId}?${query}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'x-ebiai-account-id': clientId
    }
  });
}

export default async function getFlowVersion(
  authUrl,
  flowId,
  params,
  token,
  clientId,
  message
) {
  const response = await get(authUrl, flowId, params, token, clientId);
  if (response.ok) {
    const responseVersion = await response.json();
    return responseVersion;
  }
  const error = await response.json();
  message.error(error.message);
}
