import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AuthorisationGate from './AuthorisationGate';
import ErrorBoundary from './ErrorBoundary';
import { SettingsProvider } from './useSettings';

export default function ModuleLoader({
  children,
  permission,
  settings = {},
  unauthenticated
}) {
  if (unauthenticated) {
    return (
      <React.Suspense fallback={<Loading />}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </React.Suspense>
    );
  }

  return (
    <AuthorisationGate permission={permission}>
      <React.Suspense fallback={<Loading />}>
        <SettingsProvider value={settings}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </SettingsProvider>
      </React.Suspense>
    </AuthorisationGate>
  );
}

ModuleLoader.propTypes = {
  children: PropTypes.node.isRequired,
  permission: PropTypes.string.isRequired,
  settings: PropTypes.object
};

function Loading() {
  return (
    <LoadingContainer>
      <Spin />
    </LoadingContainer>
  );
}

Loading.propTypes = {};

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
