import { useEffect, useState } from 'react';
import {
  useSelectedClient,
  useAuthentication,
  useFetch
} from './authentication';

export default () => {
  const { selectedClient } = useSelectedClient();
  const { user } = useAuthentication();
  const [banner, setBanner] = useState({});

  const { data } = useFetch(
    `${process.env.AUTH_URL as string}/clients/${selectedClient}/user/${
      user.id
    }/bannerData`
  );

  useEffect(() => {
    if (data) {
      setBanner(data);
    }
  }, [data]);

  return banner;
};
