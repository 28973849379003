import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useAuthentication } from './provider';

const Selections = createContext({});

export const useClient = () => {
  const { client, setClient, refreshClient } = useContext(Selections);
  return { client, setClient, refreshClient };
};

export const useSelectedClient = () => {
  const { client, setClient, refreshClient } = useContext(Selections);
  return {
    selectedClient: client._id,
    setSelectedClient: setClient,
    refreshSelectedClient: refreshClient
  };
};

export function ClientProvider({ children, loadingFallback }) {
  const { user, token, refreshToken } = useAuthentication();

  const [state, setState] = useState({ isLoading: true });

  const setClient = useCallback(
    async clientId => {
      setState({
        isLoading: true
      });

      const client = await getClient(clientId, token);
      sessionStorage.setItem('selectedClientId', clientId);

      await refreshToken();

      setState({
        isLoading: false,
        client: client
      });
    },
    [token, refreshToken]
  );

  const refreshClient = useCallback(async () => {
    const client = await getClient(state.client._id, token);
    setState({
      client: client
    });
  }, [token, state]);

  useEffect(() => {
    const bootstrapSelections = async () => {
      const clientIds = user.clients;

      const previousSelectedClient = sessionStorage.getItem('selectedClientId');
      const isUserClient = clientIds.includes(previousSelectedClient);
      const clientId =
        previousSelectedClient && isUserClient
          ? previousSelectedClient
          : clientIds[0];

      setClient(clientId);
    };

    bootstrapSelections();

    // Set to only run once at launch, to preventive infinite looping as dependencies change
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Selections.Provider value={{ ...state, setClient, refreshClient }}>
      {state.isLoading ? loadingFallback : children}
    </Selections.Provider>
  );
}

ClientProvider.propTypes = {
  loadingFallback: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

async function getClient(clientId, token) {
  const response = await fetch(
    `${process.env.AUTH_URL}/clients/${clientId}/client`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    }
  );
  return response.json();
}
