import {
  isAfter,
  parseISO,
  intervalToDuration,
  differenceInSeconds
} from 'date-fns';

export const getElapsedTimeMessage = chat => {
  const endUserMessages = chat.events.filter(
    event => event.etype === 'message' && event.user === chat.user
  );
  const agentMessages = chat.events.filter(
    event => event.etype === 'message' && event.user === chat.assignedUser
  );
  const lastUserMessage = endUserMessages[endUserMessages.length - 1];
  const lastAgentMessage = agentMessages[agentMessages.length - 1];

  let difference, interval;

  if (
    (!lastUserMessage && !lastAgentMessage) ||
    (lastUserMessage && !lastAgentMessage)
  ) {
    difference = differenceInSeconds(new Date(), parseISO(chat.created));
    interval = intervalToDuration({ start: 0, end: difference * 1000 });
  }

  if (
    (!lastUserMessage && lastAgentMessage) ||
    (lastUserMessage &&
      lastAgentMessage &&
      isAfter(parseISO(lastAgentMessage.date), parseISO(lastUserMessage.date)))
  ) {
    interval = false;
  }

  if (
    lastUserMessage &&
    lastAgentMessage &&
    isAfter(parseISO(lastUserMessage.date), parseISO(lastAgentMessage.date))
  ) {
    const firstMessageInLastMessageSet = endUserMessages.filter(
      message => message.date > lastAgentMessage.date
    )[0];
    difference = differenceInSeconds(
      new Date(),
      parseISO(firstMessageInLastMessageSet.date)
    );
    interval = intervalToDuration({ start: 0, end: difference * 1000 });
  }

  if (interval) {
    return {
      timer: true,
      timerMsg: `${interval.minutes
        .toString()
        .padStart(2, '0')}:${interval.seconds.toString().padStart(2, '0')}`
    };
  } else {
    return {
      timer: false,
      timerMsg: ''
    };
  }
};
