import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import {
  useSelectedBot,
  useAuthentication,
  useSelectedClient
} from '../../../../uni-comms-api/hooks/authentication';
import { logger } from '../../../../utils';


const apiUrl = process.env.AUTH_URL
  ? process.env.AUTH_URL.replace('http', 'ws')
  : '';
const connectionTimeout = 10 * 1000;
const SocketContext = createContext(undefined);

const StudioEventsProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { selectedClient } = useSelectedClient();

  const { selectedBot } = useSelectedBot();
  const { token, user } = useAuthentication();
  const socketOptions = useMemo(
    () => ({
      query: {
        client: selectedClient,
        token: token,
        botId: selectedBot._id,
        userId: user.id
      },
      reconnectionAttempts: 'Infinity',
      timeout: connectionTimeout,
      transports: ['websocket'],
      autoconnect: true
    }),
    [selectedBot._id, selectedClient, token, user.id]
  );


  useEffect(() => {
    const newSocket = io(`${apiUrl}/api/studioEvents`, socketOptions);
    setSocket(newSocket);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketOptions]);

  useEffect(() => {
    if (!socket) { return; }

    socket.on('connect', () => {
      logger('Socket connected to studio events:', socket.connected);
      const channel = `${selectedClient}-${selectedBot._id}`;
      socket.emit('join', channel);
      logger('joined to channel:', channel);
    });

    socket.on('error', () => {
      logger('Socket failed to connect to studio events:', socket.connected);
    });

    return () => {
      if (socket?.connected) {
        socket.disconnect();
        socket.off('connect');
        logger('socket is disconnected:', socket.disconnected);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);


  const contextValue = useMemo(() => ({ socket }), [socket]);
  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

export const useStudioEventSocketContext = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error(
      'useSocketContext must be used within a StudioEventProvider'
    );
  }
  return context;
};

export default StudioEventsProvider;

StudioEventsProvider.propTypes = {
  children: PropTypes.node.isRequired
};
