import React, { useEffect } from 'react';
import styled from 'styled-components';
import { message, Layout } from 'antd';

import Logo from './Logo';
import Menu from './menu/Menu';
import AvatarMenu from './AvatarMenu';
import {
  useAuthentication,
  useSelectedClient,
  useUserStatus
} from '../../uni-comms-api/hooks/authentication';
import ping from '../../uni-comms-api/hooks/ping';

export default function Header() {
  const { user, token } = useAuthentication();
  const { selectedClient } = useSelectedClient();
  const { userStatus } = useUserStatus();

  const [messageApi, messageContextHolder] = message.useMessage();

  useEffect(() => {
    if (token) {
      let interval = setInterval(() => {
        ping(selectedClient, user.id, userStatus, token);
      }, 59000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      {messageContextHolder}
      <HeaderWrapper>
        <Logo />
        <Menu />
        <AvatarMenu messageApi={messageApi} />
      </HeaderWrapper>
    </>
  );
}

const HeaderWrapper = styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 2;
`;
