const ALPHABET =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const STATE_LENGTH = 64;
const OAUTH_STATE_KEY = 'oauth-state';

export function generateState() {
  const state = randomString(STATE_LENGTH);
  localStorage.setItem(OAUTH_STATE_KEY, state);
  return state;
}

export function testState(state) {
  const oldState = localStorage.getItem(OAUTH_STATE_KEY);
  return state === oldState;
}

function randomString(length) {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);

  const randomNumbers = Array.from(array);

  const randomCharacters = randomNumbers.map(
    x => ALPHABET[x % ALPHABET.length]
  );

  return randomCharacters.join('');
}
