const SET_READY = 'set-ready';
const SET_LOADING = 'set-loading';

export const initialState = {
  loading: true,
  ready: false
};

export default function reduce(state, action) {
  switch (action.type) {
    case SET_READY:
      return {
        ...state,
        ready: action.value
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.value
      };
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}

export function setReady(bot) {
  return {
    type: SET_READY,
    value: bot
  };
}

export function setLoading(value) {
  return {
    type: SET_LOADING,
    value
  };
}
