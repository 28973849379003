import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Menu } from 'antd';
import useClients from '../hooks/useClients';
import {
  useSelectedBot,
  useSelectedClient
} from '../../../uni-comms-api/hooks/authentication';
import { useForm } from 'react-hook-form';
import useDebounce from '../../../modules/flows/flows/useDebounce';
import searchBarIcon from '../../../assets/search-icon.svg';
import { colors } from '../../../constants';

export default function SelectClientSubMenu({ ...props }) {
  const { clients } = useClients();
  const { selectedClient, setSelectedClient } = useSelectedClient();
  const { selectedBot } = useSelectedBot();

  const { register, watch } = useForm();
  const search = useDebounce(watch('search') || null, 250);

  const filteredClients = useMemo(() => {
    if (search) {
      return clients.filter(d =>
        [d.id, d.name].join(' ').toLowerCase().includes(search.toLowerCase())
      );
    } else {
      return clients;
    }
  }, [search, clients]);

  const maximized = clients.length > 20;
  if (clients.length < 2) {
    return null;
  }

  const onChangeClient = clientId => {
    const messengerClientKey = Object.keys(sessionStorage).find(key =>
      new RegExp(`^lobster/${selectedClient}/${selectedBot?.pipelineId}`).test(
        key
      )
    );
    setSelectedClient(clientId);

    if (messengerClientKey) {
      sessionStorage.removeItem(messengerClientKey);
      sessionStorage.removeItem(
        `${selectedClient}/${selectedBot?.pipelineId}/chatId`
      );
    }
  };

  return (
    <Container maximized={maximized}>
      {maximized && (
        <SearchBar icon={searchBarIcon}>
          <Input
            aria-label="search"
            {...register('search')}
            placeholder="Search"
            autoComplete="off"
          />
        </SearchBar>
      )}
      <ClientsListContainer maximized={maximized}>
        {filteredClients.length
          ? filteredClients.map(client => (
              <StyledMenuItem
                key={client.id}
                {...props}
                isSelected={client.id === selectedClient}
                onClick={() => onChangeClient(client.id)}
              >
                {client.name}
              </StyledMenuItem>
            ))
          : 'No search results'}
      </ClientsListContainer>
    </Container>
  );
}

const Container = styled.div`
  ${props =>
    props.maximized
      ? `
  padding: 15px 8px;
  min-width: 228px;`
      : `  
  max-height: 330px;
  overflow-y: auto;`}
`;
const StyledMenuItem = styled(Menu.Item)`
  padding: 4px 12px !important;
  ${({ isSelected }) => (isSelected ? 'background-color: #f5f5f5' : '')}
`;
const ClientsListContainer = styled.div`
  ${props =>
    props.maximized &&
    `
    margin-top: 20px;
    max-height: 290px;
    overflow-y: auto;
  `}
`;

const SearchBar = styled.div`
  flex: 1;
  position: relative;
  height: 40px;

  ::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 8px;
    width: 20px;
    height: 20px;
    background-position: center;
    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    scale: 0.8;
  }
`;

const Input = styled.input`
  width: 100%;
  border-radius: 10px;
  padding: 10px 12px;
  padding-left: 38px;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.DARK_BLUE};
  border: 1px solid #70707026;
  background-color: #06233d0d;

  ::placeholder {
    font-size: 14px;
    line-height: 19px;
    color: ${colors.GREY_TEXT};
  }

  :focus-visible {
    border: 1px solid ${colors.DARK_BLUE};
    outline: none;
  }
`;
