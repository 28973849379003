import { logger, displayError } from '../utils';
import HTTPError from 'standard-http-error';

export default async (token, userId, clientId, data) => {
  try {
    const response = await fetch(
      `${process.env.AUTH_URL}/clients/${clientId}/user/${userId}/status`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(data)
      }
    );

    if (!response.ok) {
      const { errors, message } = await response.json();
      throw new HTTPError(response.status, { errors, message });
    }

    return response.json();
  } catch (error) {
    logger(error);
    displayError(error.status);
    return null;
  }
};
