import { useEffect, useState } from 'react';
import { useFetch } from '../../../uni-comms-api/hooks/authentication';

export default () => {
  const [clients, setClients] = useState([]);

  const { data: newClients, loading, error } = useFetch(
    `${process.env.AUTH_URL}/clients`
  );

  useEffect(() => {
    if (newClients && newClients.length) {
      setClients(newClients);
    }
  }, [newClients]);

  return { clients, loading, error };
};
