import React from 'react';
export default function DashboardMenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.221"
      height="13.218"
      viewBox="0 0 14.221 13.218"
    >
      <g
        id="Group_808"
        data-name="Group 808"
        transform="translate(280.477 -396.393)"
      >
        <path
          id="Path_4530"
          data-name="Path 4530"
          d="M-270.1,428.866l1.465-.718c.188-.092.375-.185.564-.274a1.181,1.181,0,0,1,1.709,1.077q0,1.482,0,2.964A1.177,1.177,0,0,1-268.086,433c-.613-.3-1.223-.6-1.835-.894l-.174-.082c0,.293,0,.568,0,.844a1.232,1.232,0,0,1-1.315,1.315h-7.651a1.231,1.231,0,0,1-1.315-1.315q0-2.431,0-4.863a1.221,1.221,0,0,1,1.3-1.3h7.694a1.219,1.219,0,0,1,1.291,1.29C-270.095,428.275-270.1,428.557-270.1,428.866Zm-5.134-1.228h-3.811c-.314,0-.4.086-.4.4v4.8c0,.317.091.407.409.408h7.593c.315,0,.41-.095.41-.409q0-2.4,0-4.8c0-.308-.089-.4-.4-.4Zm7.938,2.81c0-.486,0-.973,0-1.459,0-.255-.171-.366-.4-.257-.773.376-1.544.756-2.317,1.13a.145.145,0,0,0-.091.158c0,.282.005.564,0,.846a.155.155,0,0,0,.106.164c.76.368,1.519.741,2.278,1.111.254.123.42.019.421-.263C-267.291,431.4-267.292,430.924-267.292,430.447Z"
          transform="translate(0 -24.671)"
          strokeWidth="0.2"
        />
        <path
          id="Path_4531"
          data-name="Path 4531"
          d="M-278.032,401.167a2.332,2.332,0,0,1-2.339-2.349,2.342,2.342,0,0,1,2.354-2.324,2.342,2.342,0,0,1,2.318,2.361A2.333,2.333,0,0,1-278.032,401.167Zm0-.935a1.4,1.4,0,0,0,1.4-1.389,1.407,1.407,0,0,0-1.406-1.413,1.4,1.4,0,0,0-1.4,1.408A1.4,1.4,0,0,0-278.03,400.232Z"
          transform="translate(-0.004 -0.001)"
          strokeWidth="0.2"
        />
        <path
          id="Path_4532"
          data-name="Path 4532"
          d="M-250,401.166a2.332,2.332,0,0,1-2.333-2.355,2.343,2.343,0,0,1,2.361-2.317,2.342,2.342,0,0,1,2.311,2.367A2.333,2.333,0,0,1-250,401.166Zm.005-.935a1.4,1.4,0,0,0,1.4-1.372,1.4,1.4,0,0,0-1.4-1.43,1.4,1.4,0,0,0-1.4,1.39A1.4,1.4,0,0,0-249.993,400.231Z"
          transform="translate(-22.904)"
          strokeWidth="0.2"
        />
        <path
          id="Path_4533"
          data-name="Path 4533"
          d="M-259.047,445.02a.474.474,0,0,1-.466.466.471.471,0,0,1-.468-.478.464.464,0,0,1,.473-.454A.464.464,0,0,1-259.047,445.02Z"
          transform="translate(-16.656 -39.25)"
          strokeWidth="0.2"
        />
        <path
          id="Path_4534"
          data-name="Path 4534"
          d="M-248.85,445.021a.471.471,0,0,1-.465.466.474.474,0,0,1-.469-.463.464.464,0,0,1,.473-.469A.466.466,0,0,1-248.85,445.021Z"
          transform="translate(-24.984 -39.25)"
          strokeWidth="0.2"
        />
      </g>
    </svg>
  );
}
