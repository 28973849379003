import { useSelectedClient, useFetch } from './authentication';

export default () => {
  const { selectedClient } = useSelectedClient();

  const { data, loading } = useFetch(
    `${process.env.BILLING_URL}/billing/clients/${selectedClient}/licence`
  );

  return { data, loading };
};
