import React, { useCallback, useState } from 'react';
import Button from '../../modules/flows/presentational/Button';
import { useSelectedBot } from '../../uni-comms-api/hooks/authentication';
import FlowsAPI from '../../uni-comms-api/Flows';
import PublishFlowsMessageModal from '../modals/PublishFlowsMessageModal';
import styled from 'styled-components';
import TryMyAiModal from '../modals/TryMyAiModal';

export interface TestAIButtonProps {
  className?: string;
  color?: 'red' | 'green' | 'white';
  kind?: 'outlined' | 'filled' | 'outlined-filled' | 'standard';
  showSplashScreen?: boolean;
  studioEvent?: () => void;
}

const TestAIButton: React.FC<TestAIButtonProps> = ({
  color = 'red',
  kind = 'filled',
  showSplashScreen = false,
  className,
  studioEvent
}) => {
  const { selectedBot } = useSelectedBot();
  const [open, setOpen] = useState(false);
  const clientId = selectedBot?.client ?? '';
  const pipelineId = selectedBot?.pipelineId ?? '';

  const { data: publishedFlows, isLoading: isLoadingPublishedFlows } =
    FlowsAPI.useList({
      assistant: selectedBot._id,
      trigger: 'intent',
      filters: ['published'],
      excludeClasses: ['small-talk']
    });
  const [openSplashScreenModal, setOpenSplashScreenModal] =
    useState<boolean>(false);

  const hasFlows =
    !isLoadingPublishedFlows &&
    publishedFlows &&
    publishedFlows.pages[0]?.count > 0;

  const handleOnTryAIButton = useCallback(() => {
    if (hasFlows) {
      const baseUrl = process.env.MESSENGER_URL;
      const url = `${
        baseUrl as string
      }?client=${clientId}&pipeline=${pipelineId}&startOpened=true`;
      window.open(url, '_blank');
      setOpenSplashScreenModal(false);
    } else {
      setOpen(value => !value);
    }
  }, [clientId, hasFlows, pipelineId]);

  return (
    <>
      <TryYourAIButton
        onClick={
          showSplashScreen
            ? () => {
                setOpenSplashScreenModal(true);
                studioEvent && studioEvent();
              }
            : handleOnTryAIButton
        }
        color={color}
        kind={kind}
        className={className}
      >
        Try my AI
      </TryYourAIButton>
      <PublishFlowsMessageModal open={open} setOpen={setOpen} />
      <TryMyAiModal
        open={openSplashScreenModal}
        onCancel={() => setOpenSplashScreenModal(false)}
        onOk={handleOnTryAIButton}
      />
    </>
  );
};

const TryYourAIButton = styled(Button)`
  max-width: 127px;
  height: 42px;
  font-size: 16px;
`;
export default TestAIButton;
