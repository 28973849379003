import { logger } from '../utils/logger';

async function get(teamId, authUrl, token, selectedClient) {
  return await fetch(`${authUrl}/clients/${selectedClient}/team/${teamId}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
}

export default async function getMembers(
  teamId,
  authUrl,
  token,
  selectedClient,
  expanded
) {
  try {
    const response = await get(teamId, authUrl, token, selectedClient);

    if (response.ok) {
      const members = await response.json();

      if (expanded) {
        return members?.users.map(({ _id, status }) => ({ _id, status })) || [];
      } else {
        return transformRequest(members);
      }
    } else {
      const error = await response.json();
      logger(error.message);
    }
  } catch (e) {
    logger(e);
  }
}

function transformRequest(currentTeam) {
  return currentTeam?.users.reduce(
    (members, member) => {
      const { online = 0, onbreak = 0, offline = 0 } = members;

      let result;

      if (member.status === 'online') {
        result = { ...members, online: online + 1 };
      } else if (member.status === 'on_break') {
        result = { ...members, onbreak: onbreak + 1 };
      } else {
        result = { ...members, offline: offline + 1 };
      }

      result = { ...result, total: currentTeam?.users.length };

      return result;
    },
    { online: 0, onbreak: 0, offline: 0, total: 0 }
  );
}
