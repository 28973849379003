async function get(selectedClient, team, token, pageIndex, pageSize) {
  return await fetch(
    `${process.env.AUTH_URL}/clients/${selectedClient}/team/${team._id}/recentChats?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  );
}

export default async function getRecentChats(
  selectedClient,
  team,
  token,
  message,
  pageIndex,
  pageSize
) {
  try {
    const response = await get(
      selectedClient,
      team,
      token,
      pageIndex,
      pageSize
    );
    if (response.ok) {
      const recentChats = await response.json();
      return recentChats;
    }
    const error = await response.json();
    message.error(error.message);
  } catch {
    message.error('Error connecting to server');
  }
}
