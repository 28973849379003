import { buildUsePutHook } from './genericHooks';

export interface Tag {
  _id: string;
  tag: string;
  user: string;
  date: string;
}

const name = 'tag';

export default {
  usePut: buildUsePutHook<Tag, null>(name)
};
