import React from 'react';
export default function DashboardMenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.569"
      height="13.648"
      viewBox="0 0 13.569 13.648"
    >
      <g
        id="Group_764"
        data-name="Group 764"
        transform="translate(645.618 -139.334)"
      >
        <g
          id="Group_765"
          data-name="Group 765"
          transform="translate(-645.368 139.656)"
        >
          <g id="Group_764-2" data-name="Group 764">
            <path
              id="Path_4505"
              data-name="Path 4505"
              d="M-633.225,157.017v-6.984a.218.218,0,0,0-.218-.218h-2.178a.218.218,0,0,0-.218.218v6.984H-637.8v-5.705a.218.218,0,0,0-.218-.218h-2.178a.218.218,0,0,0-.218.218v5.705H-642.1V152.7a.218.218,0,0,0-.218-.218H-644.5a.218.218,0,0,0-.218.218v4.315h-.653v.435H-632.3v-.435Zm-9.312,0h-1.743v-4.1h1.743Zm4.3,0h-1.743v-5.487h1.743Zm4.574,0H-635.4v-6.766h1.742Z"
              transform="translate(645.368 -144.376)"
              strokeWidth="0.5"
            />
            <path
              id="Path_4506"
              data-name="Path 4506"
              d="M-643.253,145.582a1.089,1.089,0,0,0,.6.18,1.089,1.089,0,0,0,1.089-1.089,1.088,1.088,0,0,0-.181-.6l1.542-1.542a1.082,1.082,0,0,0,.6.182,1.094,1.094,0,0,0,.192-.017,1.075,1.075,0,0,0,.552-.276l1.53.929a1.089,1.089,0,0,0,.626,1.408,1.089,1.089,0,0,0,1.408-.626,1.089,1.089,0,0,0-.11-.993l1.3-1.477a1.092,1.092,0,0,0,1.513-.308,1.092,1.092,0,0,0-.308-1.514,1.093,1.093,0,0,0-1.514.308,1.093,1.093,0,0,0,0,1.206l-1.3,1.477a1.089,1.089,0,0,0-1.37.138l-.011.013-1.51-.914a1.089,1.089,0,0,0-.552-1.438,1.089,1.089,0,0,0-1.438.552,1.087,1.087,0,0,0,.087,1.043l-1.541,1.541a1.089,1.089,0,0,0-1.509.308A1.09,1.09,0,0,0-643.253,145.582Zm9.75-5.483a.653.653,0,0,1,.653.654.653.653,0,0,1-.653.654.654.654,0,0,1-.654-.654A.654.654,0,0,1-633.5,140.1Zm-3.275,3.177a.652.652,0,0,1,.463-.191.653.653,0,0,1,.654.653.652.652,0,0,1-.192.462.669.669,0,0,1-.924,0A.654.654,0,0,1-636.779,143.275Zm-3.359-2.027h0a.649.649,0,0,1,.421-.269.692.692,0,0,1,.115-.01.649.649,0,0,1,.374.118.653.653,0,0,1,.16.91.653.653,0,0,1-.91.16A.653.653,0,0,1-640.138,141.248Zm-2.977,2.963h0a.654.654,0,0,1,.925,0,.654.654,0,0,1,0,.924.67.67,0,0,1-.924,0A.654.654,0,0,1-643.114,144.211Z"
              transform="translate(644.612 -139.656)"
              strokeWidth="0.5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
