export const settingsPermission = {
  VIEW_SETTINGS: 'settings:read',
  VIEW_MEMBERS: 'settingsMembers:read',
  VIEW_MEMBER: 'settings:read',
  ADD_MEMBERS: 'settingsMembers:write',
  VIEW_LIVECHAT: 'settings:read',
  VIEW_JOURNEYS: 'journeys:read',
  ADD_JOURNEYS: 'journeys:write',
  VIEW_JOURNEY_PIPELINE: 'journeys:read',
  VIEW_APP: 'apps:read',
  ADD_APPS: 'apps:write',
  VIEW_APPS: 'apps:read'
};

export default { settingsPermission };
