import { buildUseCreateHook, buildUseGetHook } from './genericHooks';

const AUTO_GEN_URL = process.env.AUTO_GEN_URL || '';

export type AutogenStatus =
  | 'CREATED'
  | 'PENDING'
  | 'PROCESSING'
  | 'COMPLETE'
  | 'FAILED';

export interface AutoGenJobProps {
  status: AutogenStatus;
  client: string;
}

export interface CreateAutogenParams {
  assistant: {
    _id: string;
    displayName?: string;
  };
  url: string;
}

const name = 'autoGen';

const path = `${AUTO_GEN_URL}/autoGenerator/job`;
export default {
  useGet: buildUseGetHook<AutoGenJobProps, Record<string, string | number>>(
    name,
    path
  ),
  useCreate: buildUseCreateHook<AutoGenJobProps, CreateAutogenParams>(
    name,
    path
  )
};
