import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function NotAuthorised() {
  const navigate = useNavigate();
  return (
    <Result
      status="403"
      title="403"
      subTitle="This page requires more privileges to access."
      extra={
        <Button type="primary" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      }
    />
  );
}
