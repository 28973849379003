import React from 'react';
import styled from 'styled-components';
import useClients from '../hooks/useClients';
import { useSelectedClient } from '../../../uni-comms-api/hooks/authentication';

export default function SelectedClientTitle() {
  const { clients } = useClients();
  const { selectedClient } = useSelectedClient();
  const client = clients.find(item => item.id === selectedClient);

  return (
    clients.length && (
      <ClientStatus>
        <ClientStatusName>{client.name}</ClientStatusName>
        <ClientStatusId>{client.id}</ClientStatusId>
      </ClientStatus>
    )
  );
}

const ClientStatus = styled.div``;
const ClientStatusName = styled.div``;
const ClientStatusId = styled.div`
  font: 0.8rem;
  color: gray;
`;
