async function post(authURL, selectedClient, botId, token, imageData) {
  return await fetch(
    `${authURL}/clients/${selectedClient}/bots/${botId}/uploadAvatar`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: imageData
    }
  );
}

export default async function postBotAvatar(
  authURL,
  selectedClient,
  botId,
  token,
  message,
  imageData
) {
  try {
    const response = await post(
      authURL,
      selectedClient,
      botId,
      token,
      imageData
    );

    if (response.ok) {
      const imageUrl = await response.json();
      return imageUrl;
    }
    const error = await response.json();
    message.error(error.message);
  } catch {
    message.error('Error connecting to server');
  }
}
