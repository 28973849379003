import React from 'react';
import styled from 'styled-components';
import { Dropdown, Avatar } from 'antd';
import {
  useAuthentication,
  useUserStatus
} from '../../../uni-comms-api/hooks/authentication';

import MenuOverlay from './MenuOverlay';
import { Status } from './StatusSubMenuItems';
import { getStyle } from '../../../utils';

export default function AvatarMenu(props) {
  const { user } = useAuthentication();
  const initials = user.firstName[0] + user.lastName[0];

  const { userStatus } = useUserStatus();

  return (
    <AvatarContainer>
      <DropdownButton
        role="button"
        tabindex="0"
        overlay={<MenuOverlay {...props} />}
        trigger={['click']}
      >
        <Avatar>{initials}</Avatar>
      </DropdownButton>
      <OnlineStatusBorder>
        <OnlineStatus bgColor={getStyle(userStatus)} />
      </OnlineStatusBorder>
    </AvatarContainer>
  );
}

const AvatarContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

const DropdownButton = styled(Dropdown)`
  cursor: pointer;
  user-select: none;
`;

const OnlineStatusBorder = styled.div`
  border: 3px solid #06233d;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  margin-top: 18px;
  margin-left: -8px;
  z-index: 1;
`;

const OnlineStatus = styled(Status)`
  height: 12px;
  width: 12px;
  border-radius: 100%;
  margin-top: 0;
  margin-left: 0;
  z-index: 1;
`;
