import { useEffect, useState } from 'react';
import {
  useSelectedClient,
  useFetch
} from '../../uni-comms-api/hooks/authentication';

export default () => {
  const { selectedClient } = useSelectedClient();
  const [bots, setBots] = useState([]);

  const { data, loading, error } = useFetch(
    `${process.env.AUTH_URL}/clients/${selectedClient}/bots`
  );

  useEffect(() => {
    if (data && data.items.length) {
      setBots(data.items);
    }
  }, [data]);

  return { bots, loading, error };
};
