import React, { useCallback, useState, ComponentType } from 'react';
import styled from 'styled-components';
import { colors } from '../constants';
import { CloseOutlined } from '@ant-design/icons';
import { useStudioEventSocketContext } from '../modules/flows/flows/context/StudioEventsProvider';
import TestAIButton, { TestAIButtonProps } from './buttons/TestAiButton';
import LaunchAI, { LaunchAIProps } from './header/LaunchAI';
import { ModalProvider } from './header/ModalManager';
import {
  useAuthentication,
  useSelectedClient
} from '../uni-comms-api/hooks/authentication';
import { BannerState } from '../constants';

import useNotificationBanner from '../uni-comms-api/hooks/useNotificationBanner';

interface StylingProps {
  [index: string]: string | boolean;
}
interface BannerButtonProps {
  [key: string]: {
    component: ComponentType<TestAIButtonProps | LaunchAIProps>;
    props: StylingProps;
  };
}

const TryMyAiButton = styled(TestAIButton)`
  height: 45px;
  width: 120px;
  font-size: 14px;
  font-weight: bold;
`;

const BannerButtons: BannerButtonProps = {
  'try-ai-button': {
    component: TryMyAiButton,
    props: {
      color: 'white',
      kind: 'outlined',
      showSplashScreen: true
    }
  },
  'launch-ai-button': {
    component: LaunchAI,
    props: { label: 'Launch your AI' }
  }
};

interface Message {
  user: string;
  client: string;
  banner: string;
  status: string;
}

interface SocketProps {
  emit: (event: string, data: Message) => void;
}

const NotificationsBanner: React.FC = () => {
  const { socket } = useStudioEventSocketContext() as { socket: SocketProps };
  const { selectedClient } = useSelectedClient();
  const { user } = useAuthentication();
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const bannerData: Record<string, unknown> = useNotificationBanner();

  const studioEventEmitter = useCallback(
    (event: string, status: string) => {
      const message: Message = {
        user: user.id,
        client: selectedClient,
        banner: bannerData._id as string,
        status
      };
      if (status === BannerState.DISMISSED) {
        setIsVisible(false);
      }
      socket.emit(event, message);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bannerData]
  );

  if (isVisible && bannerData.textContent) {
    return (
      <Container>
        <ModalProvider>
          <Text>
            <Label>{bannerData.textContent}</Label>
          </Text>

          {bannerData?.buttonId &&
            Object.entries(BannerButtons).map(
              ([key, { component: Component, props }]) =>
                key === bannerData?.buttonId ? (
                  <NotificationButtonContainer key={`${key}-container`}>
                    <Component
                      key={key}
                      {...props}
                      studioEvent={() =>
                        studioEventEmitter(
                          'banner.cta-clicked',
                          BannerState.READ // TODO: change Banner status - TBD
                        )
                      }
                    />
                  </NotificationButtonContainer>
                ) : null
            )}
          <CloseOutlined
            size={12}
            style={{
              marginRight: '20px'
            }}
            onClick={() =>
              studioEventEmitter('banner.closed', BannerState.DISMISSED)
            }
          />
        </ModalProvider>
      </Container>
    );
  }
  return null;
};

export default NotificationsBanner;

const Container = styled.div`
  display: flex;
  background: ${colors.GREEN};
  color: white;
  max-height: 60px;
  font-size: 16px;
  z-index: 100;
`;

const Text = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2%;
  margin-top: 15px;
  width: 100%;
  padding: 5px;
  flex-direction: row;
`;

const Label = styled.label`
  margin: 25px 25px;
`;

const NotificationButtonContainer = styled.div`
  margin: 5px 20px;
`;
