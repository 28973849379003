import { uniq } from 'lodash';
import React, { createContext, useContext, useState, ReactNode } from 'react';

type ModalContextType = {
  activeModal: string | null;
  openModal: (modalId: string) => void;
  closeModal: () => void;
  isActiveModal: (modalId: string) => boolean;
  openedModals: string[];
};

const ModalContext = createContext<ModalContextType>({
  activeModal: null,
  openModal: () => {
    // do nothing as default
  },
  openedModals: [],
  closeModal: () => {
    // do nothing as default
  },
  isActiveModal: () => {
    return false;
  }
});

export const useModalManager = () => useContext(ModalContext);

type ModalProviderProps = {
  children: ReactNode;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [openedModals, setOpenedModals] = useState<string[]>([]);

  const openModal = (modalId: string) => {
    setActiveModal(modalId);
    setOpenedModals(modals => uniq([...modals, modalId]));
  };

  const closeModal = () => setActiveModal(null);
  const isActiveModal = (modalId: string) => activeModal === modalId;

  return (
    <ModalContext.Provider
      value={{
        activeModal,
        openModal,
        closeModal,
        isActiveModal,
        openedModals
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
