import { cloneDeep } from 'lodash';

export default function injectClawson(messengerUrl) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = messengerUrl;
    script.async = true;
    script.addEventListener('load', () => {
      window.clawsonChatBot = cloneDeep(window.chatBot);
      delete window.chatBot;
      resolve();
    });
    script.addEventListener('error', e => reject(e.error));
    document.body.appendChild(script);
  });
}
