import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import { useAuthentication } from './provider';
import { useClient } from './ClientProvider';

const Selections = createContext({});

export const useSelectedBot = () => {
  const { bot, setBot, refreshBot } = useContext(Selections);
  const isSelfServe = Boolean(bot.signalProvider);

  return { selectedBot: bot, setSelectedBot: setBot, refreshBot, isSelfServe };
};

export function BotProvider({ children, loadingFallback }) {
  const { token } = useAuthentication();
  const { client } = useClient();
  const [state, setState] = useState({ isLoading: true });

  const fetchedForClient = useRef();

  useEffect(() => {
    // To avoid multiple refetching;
    if (
      fetchedForClient.current &&
      client.selectedClient &&
      fetchedForClient.current === client.selectedClient
    ) {
      return;
    }

    const bootstrapSelections = async () => {
      const bots = await getBots(client._id, token);

      if (bots) {
        fetchedForClient.current = client.selectedClient;
      }

      const previousSelectedBot = sessionStorage.getItem('selectedBotId');

      const bot = bots.find(b => b._id === previousSelectedBot);
      const botId = bot ? previousSelectedBot : bots[0]._id;

      sessionStorage.setItem('selectedBotId', botId);
      setState({
        isLoading: false,
        bot: bot || bots[0]
      });
    };

    bootstrapSelections();
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  const setBot = async newBotId => {
    setState({ isLoading: true });
    const bots = await getBots(client._id, token);
    const bot = bots.find(b => b._id === newBotId);
    const botId = bot ? newBotId : bots[0]._id;
    sessionStorage.setItem('selectedBotId', botId);
    setState({
      isLoading: false,
      bot: bot || bots[0]
    });
  };

  const refreshBot = useCallback(async () => {
    const bots = await getBots(client._id, token);
    const bot = bots.find(b => b._id === state.bot._id);
    setState({
      bot: bot
    });
  }, [token, state, client._id]);

  return (
    <Selections.Provider value={{ ...state, setBot, refreshBot }}>
      {state.isLoading ? loadingFallback : children}
    </Selections.Provider>
  );
}

BotProvider.propTypes = {
  loadingFallback: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

async function getBots(clientId, token) {
  const rawResponse = await fetch(
    `${process.env.AUTH_URL}/clients/${clientId}/bots`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    }
  );
  const response = await rawResponse.json();
  return response.items;
}
