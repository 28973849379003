import React, { useContext, useLayoutEffect, useState } from 'react';
import { useClawson, Clawson } from './shell';
import styled from 'styled-components';
import { getScrollbarWidth } from './utils';

const ClawsonLauncher = () => {
  const { open, setOpen } = useContext(Clawson);
  const [scrollBarWidth, setScrollBarWidth] = useState(0);

  useLayoutEffect(() => {
    const width = getScrollbarWidth();
    setScrollBarWidth(width);
  }, []);

  useClawson({
    embed: {
      enabled: true,
      target: 'chat_content_clawson'
    },
    customAction: {
      action: 'minimize',
      onClick: () => setOpen(current => !current)
    },
    loadWhen: open
  });

  return (
    <SlideOverClawsonContainer open={open} extraWidth={scrollBarWidth}>
      {open && <div id={'chat_content_clawson'} className="messenger" />}
    </SlideOverClawsonContainer>
  );
};

export default ClawsonLauncher;

const SlideOverClawsonContainer = styled.div`
  width: 375px;
  height: calc(100% - 110px);
  position: fixed;
  top: 94px;
  right: ${({ extraWidth = 0 }) => `${30 + extraWidth}px`};
  z-index: 1049;
  border: 0.5px solid #cdcdcd;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;

  transition: transform 0.5s ease-in-out;
  transform: ${props => (props.open ? 'translateX(0)' : 'translateX(200%)')};

  .messenger {
    width: calc(100% + 3px);
    margin-left: -1.5px;
    height: 100%;
  }
`;
