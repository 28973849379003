import React from 'react';
import styled from 'styled-components';
import logo from './AI-logo.png';

export default function Logo() {
  return (
    <CollapsingLogo>
      <a href="/">
        <LogoImage src={logo} alt="" />
      </a>
      <LogoText>Studio</LogoText>
    </CollapsingLogo>
  );
}

const CollapsingLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LogoImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  background: white;
`;

const LogoText = styled.h2`
  color: white;
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 16px;
`;
