import minimatch from 'minimatch';
import { useAuthentication } from './provider';
import { useClient } from './ClientProvider';

export function permissionMatches(requestedPermission) {
  const [requestedResource, requestedAction] = requestedPermission.split(':');
  const globOptions = {
    noext: true,
    dot: true,
    nocomment: true,
    nonegate: true
  };

  return userPermission => {
    const [permittedResource, permittedAction] = userPermission.split(':');

    const actionDoesMatch = permittedAction === requestedAction;
    const resourceDoesMatch = minimatch(
      requestedResource,
      permittedResource,
      globOptions
    );

    return actionDoesMatch && resourceDoesMatch;
  };
}

export default function useIsAuthorized(permission) {
  const { user } = useAuthentication();
  const { client } = useClient();

  const permissions = user.clientPermissions[client._id] || [];

  return permissions.some(permissionMatches(permission));
}
