import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelectedBot } from '../../../uni-comms-api/hooks/authentication';

export default function SelectedBotTitle() {
  const { selectedBot } = useSelectedBot();
  const [botName, setBotName] = useState('My AI assistant');

  useEffect(() => {
    let name = selectedBot?.displayName || 'My AI assistant';
    name = name.replace(/_/g, ' ');
    setBotName(name);
  }, [selectedBot]);

  return (
    <BotNameWrapper>
      <BotName>{botName}</BotName>
      <BotNameSubtitle>Selected assistant</BotNameSubtitle>
    </BotNameWrapper>
  );
}

const BotNameWrapper = styled.div``;
const BotName = styled.div``;
const BotNameSubtitle = styled.div`
  font: 0.8rem;
  color: gray;
`;
