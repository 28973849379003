async function post(billingURL, selectedClient, token, product, quantity) {
  return await fetch(`${billingURL}/billing/clients/${selectedClient}/quote`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ product, quantity })
  });
}

export default async function getQuote(
  billingURL,
  selectedClient,
  token,
  message,
  product,
  quantity
) {
  try {
    const response = await post(
      billingURL,
      selectedClient,
      token,
      product,
      quantity
    );

    if (response.ok) {
      const { amount } = await response.json();
      return amount;
    }

    const error = await response.json();
    message.error(error.message);
  } catch (e) {
    message.error('Error connecting to server');
  }
}
