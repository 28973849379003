const MESSENGER_SET_READY = 'messenger-set-ready';
const MESSENGER_SET_LOADING = 'messenger-set-loading';

export const initialState = {
  loading: true,
  ready: false
};

export default function reduce(state, action) {
  switch (action.type) {
    case MESSENGER_SET_READY:
      return {
        ...state,
        ready: action.value
      };
    case MESSENGER_SET_LOADING:
      return {
        ...state,
        loading: action.value
      };
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}

export function setReady(bot) {
  return {
    type: MESSENGER_SET_READY,
    value: bot
  };
}

export function setLoading(value) {
  return {
    type: MESSENGER_SET_LOADING,
    value
  };
}
