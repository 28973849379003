import { testState } from './state';

export default function isReadyForCodeExchange() {
  const urlParams = new URLSearchParams(window.location.search);
  const state = urlParams.get('state');
  const code = urlParams.get('code');
  const currentClient = urlParams.get('client');
  return (
    Boolean(state) &&
    Boolean(code) &&
    Boolean(currentClient) &&
    testState(state)
  );
}
