import { logger } from '../utils/logger';

async function get(authURL, selectedClient, teamId, token) {
  return await fetch(
    `${authURL}/clients/${selectedClient}/team/${teamId}/activeChats`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  );
}

export default async function getActiveChats(
  authURL,
  selectedClient,
  teamId,
  token
) {
  try {
    const response = await get(authURL, selectedClient, teamId, token);
    if (response.ok) {
      const activeChats = await response.json();
      return activeChats;
    } else {
      const error = await response.json();
      logger(error.message);
    }
  } catch (e) {
    logger(e);
  }
}
