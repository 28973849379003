export const decodeSpecialChars = value => {
  if (typeof value !== 'string') {
    return value;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(value, 'text/html');
  const decodedString = doc.documentElement.textContent.replace(
    /(<([^>]+)>)/gi,
    ''
  );
  return decodedString;
};
