import React, {
  useEffect,
  createContext,
  useContext,
  useReducer,
  useState,
  useMemo,
  useCallback
} from 'react';
import PropTypes, { string } from 'prop-types';
import reducer, { initialState, setLoading, setReady } from './reducer';
import {
  useAuthentication,
  useSelectedClient
} from '../../uni-comms-api/hooks/authentication';
import injectMessenger from './injectMessenger';
import useMember from '../../uni-comms-api/hooks/useMember';
import useClients from '../header/hooks/useClients';

export const Messenger = createContext();

export function useMessenger(options = {}) {
  const { member } = useMember('me');
  const { clients } = useClients();
  const { selectedClient } = useSelectedClient();
  const client = clients.find(item => item.id === selectedClient);
  const [mounted, setMounted] = useState(false);
  const { ready, loading, messenger } = useContext(Messenger);
  const { token } = useAuthentication();

  const shouldLoad = options.loadWhen !== undefined ? options.loadWhen : true;
  delete options.loadWhen;

  useEffect(() => {
    if (!shouldLoad && mounted) {
      setMounted(false);
    }
  }, [shouldLoad, mounted]);

  const initialize = useCallback(
    () => {
      if (member && client) {
        window.assistantChatBot.init({
          immediateLoad: true,
          metadata: {},
          authentication: {
            identity: {
              email: member.email,
              firstName: member.firstName,
              fullName: member.fullName,
              lastName: member.lastName,
              clientName: client.name
            },
            access: token
          },
          ...options
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [member, client, options, token]
  );

  useEffect(() => {
    if (shouldLoad && ready && !mounted && token && member && client) {
      initialize();
      setMounted(true);
    }
  }, [
    messenger,
    mounted,
    options,
    ready,
    shouldLoad,
    token,
    member,
    client,
    initialize
  ]);

  return { ready, loading, mounted, initialize };
}

export function MessengerProvider({ messenger, children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!state.ready || !window.assistantChatBot) {
      const staticJs =
        process.env.NODE_ENV === 'development'
          ? '/static/js/bundle.js'
          : '/static/js/main.js';

      injectMessenger(messenger.url + staticJs).then(() => {
        dispatch(setReady(true));
        dispatch(setLoading(false));
      });
    }
  }, [messenger, state.ready]);

  const value = useMemo(
    () => ({
      loading: state.loading,
      ready: state.ready,
      messenger
    }),
    [state.loading, state.ready, messenger]
  );

  return <Messenger.Provider value={value}>{children}</Messenger.Provider>;
}

MessengerProvider.propTypes = {
  messenger: PropTypes.shape({
    url: string.isRequired
  }).isRequired,
  children: PropTypes.node.isRequired
};
