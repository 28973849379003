import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Shell, ModuleLoader } from './shell';
import AppRoutes from './Routes';
import GetStarted from './modules/get-started';
import Connector from './modules/connector';
import ClawsonLauncher from './ClawsonLauncher';

const shellSettings = {
  auth: {
    authURL: process.env.AUTH_URL,
    loginURL: process.env.LOGIN_URL,
    clientID: process.env.OAUTH_CLIENT_ID
  },
  messenger: {
    url: process.env.MESSENGER_URL
  },
  clawson: {
    url: process.env.CLAWSON_MESSENGER_URL,
    client: process.env.CLAWSON_CLIENT,
    pipeline: process.env.CLAWSON_PIPELINE
  },
  billing: {
    url: process.env.BILLING_URL
  },
  bot: {
    url: process.env.BOT_URL
  }
};

const unauthenticatedSettings = {
  messenger: shellSettings.messenger
};

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="get-started"
          element={
            <ModuleLoader unauthenticated>
              <GetStarted settings={unauthenticatedSettings} />
            </ModuleLoader>
          }
        />
        <Route
          path="/connectors/oauth/code"
          element={
            <ModuleLoader unauthenticated>
              <Connector settings={unauthenticatedSettings} />
            </ModuleLoader>
          }
        />
        <Route
          path="*"
          element={
            <Shell settings={shellSettings}>
              <ClawsonLauncher />
              <AppRoutes />
            </Shell>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
