import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Menu } from 'antd';
import { useSelectedBot } from '../../../uni-comms-api/hooks/authentication';

export default function BotMenuItems({ ...props }) {
  const [selectedBots, setBots] = useState([]);
  const { selectedBot, setSelectedBot } = useSelectedBot();

  useEffect(() => {
    setBots(
      props.bots.map(bot => {
        let name = bot?.displayName || 'My AI Assistant';
        name = name.replace(/_/g, ' ');
        return { ...bot, formattedName: name };
      })
    );
  }, [props.bots]);

  return selectedBots && selectedBots.length > 1 ? (
    <>
      {selectedBots.map(bot => (
        <StyledMenuItem
          key={bot._id}
          {...props}
          isSelected={bot._id === selectedBot._id}
          onClick={() => setSelectedBot(bot._id)}
        >
          {bot.formattedName}
        </StyledMenuItem>
      ))}
    </>
  ) : null;
}

const StyledMenuItem = styled(Menu.Item)`
  ${({ isSelected }) => (isSelected ? 'background-color: #f5f5f5' : '')}
`;
