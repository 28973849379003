import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../constants';
import {
    CloseOutlined, LoadingOutlined
} from '@ant-design/icons';
import { useTags } from '../uni-comms-api/hooks';
import { useSelectedBot } from '../uni-comms-api/hooks/authentication';

import { logger } from '../utils/logger';

export default function UpdateTCSBanner(){
    const recentRelease = 'tcs:april2023';
    const [loading, setLoading] = useState(false);
    const {tagExists, ensureTag} = useTags();
    const {isSelfServe} = useSelectedBot();

    const closeTag = async () => {
        setLoading(true);
        try {
            await ensureTag(recentRelease);
        } catch (e) {
            logger(e);
        } finally {
            setLoading(false);
        }
    };

  
    if (tagExists && tagExists(recentRelease)) {
        return null;
    }

    // Do not show if managed service client.
    if (!isSelfServe) {
        return null;
    }

    return (
        <Container>
            <Text>
            We have recently updated our {' '}
            <ExternalLink 
                href="https://ebi.ai/customer-terms-and-conditions/"
                target="_blank" > Terms and Conditions</ExternalLink> {' '} and {' '}
            <ExternalLink
                href="https://ebi.ai/privacy-policy/"
                target="_blank"> Privacy Policy</ExternalLink> {' '}
            and by closing this message you accept these changes. 
            Please ask Clawson for more information.
            </Text>
            {
                loading ? <LoadingOutlined style={{ marginTop: '4px' }} /> :  <CloseOutlined size={12} style={{
                    margin: '4px 0px'
                }} onClick={closeTag}/>
            }
        </Container>
    );
}


const Container = styled.div`
    background: ${colors.GREEN};
    color: white;
    font-size: 16px;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    gap: 16px;
`;

const Text = styled.p`
    flex: 1;
    margin: 0px 0px 0px 32px;
    text-align: center;
`;

const ExternalLink = styled.a`
    font-weight: bold;
    text-decoration: underline;
    color: white;
`;
