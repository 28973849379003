import { logger } from '../../../../utils/logger';
import { omitBy } from 'lodash';

async function post(
  authURL,
  selectedClient,
  botId,
  token,
  displayName,
  avatar,
  pipeline,
  pipelineId
) {
  const body = omitBy(
    { displayName, avatar, pipeline, pipelineId },
    value => !value
  );
  return await fetch(
    `${authURL}/clients/${selectedClient}/bots/${botId}/updateTheme`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  );
}

export default async function postUpdateTheme(
  authURL,
  selectedClient,
  botId,
  token,
  displayName,
  avatar,
  pipeline,
  pipelineId
) {
  try {
    const response = await post(
      authURL,
      selectedClient,
      botId,
      token,
      displayName,
      avatar,
      pipeline,
      pipelineId
    );

    if (response.ok) {
      const imageUrl = await response.json();
      return imageUrl;
    }
    const error = await response.json();
    logger(error.message);
  } catch {
    logger('Error connecting to server');
  }
}
