import updateAgentStatus from './updateAgentStatus';

import getActiveChats from './getActiveChats';
import getMembers from './getMembers';
import getQueuedChats from './getQueuedChats';
import getRecentChats from './getRecentChats';
import getFlowVersion from './getFlowVersion';
import getFlow from './getFlow';
import getFlowsList from './getFlowsList';

export {
  getActiveChats,
  getMembers,
  getQueuedChats,
  getRecentChats,
  getFlowVersion,
  getFlow,
  getFlowsList
};

export default {
  updateAgentStatus,
  getActiveChats,
  getMembers,
  getQueuedChats,
  getRecentChats,
  getFlowVersion,
  getFlow,
  getFlowsList
};
