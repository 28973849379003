import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Header from './Header';
import styled from 'styled-components';
import { SCREEN_WIDTH, colors, fontWeight } from '../../constants';
import { ReactComponent as MessengerIcon } from './assets/messenger.svg';
import seabed from './assets/background-bottom.svg';
import Messenger from './Messenger';
import { MessengerProvider } from './messenger/provider';
import { useSearchParams } from 'react-router-dom';
import {
  GoLiveWithAi,
  OrDivider,
  ShareYourAi,
  SignIntoAi
} from './NextActions';
import getExampleFlows from './getExampleFlows';
import { useWindowSize } from '../../utils';

export default function GetStarted({ settings }) {
  const examplesRef = useRef();
  const titleRef = useRef();
  const { width } = useWindowSize();

  const [exampleFlows, setExampleFlows] = useState([]);
  const [height, setHeight] = useState(600);

  let [searchParams] = useSearchParams();

  const url = settings.messenger.url;
  const client = searchParams.get('client');
  const pipeline = searchParams.get('pipeline');

  useEffect(() => {
    getExampleFlows({ clientId: client, pipelineId: pipeline }).then(
      setExampleFlows
    );
  }, [client, pipeline]);

  useLayoutEffect(() => {
    if (examplesRef.current && titleRef.current) {
      setHeight(
        examplesRef.current.getBoundingClientRect().height +
          titleRef.current.getBoundingClientRect().height
      );
    }
  }, [exampleFlows, width]);

  return (
    <MessengerProvider
      messenger={{
        client,
        pipeline,
        url
      }}
    >
      <Header />

      <WrapColumn maxHeight={height}>
        <div className="item order-1" ref={titleRef}>
          <Title>
            Your AI assistant <br /> is ready!
          </Title>
          <Subtitle>
            Test your AI assistant by writing a message to your AI assistant 👇
          </Subtitle>
        </div>
        <div className="item order-2 order-mb-3" ref={examplesRef}>
          <Subtitle>Here are some things you can ask your AI:</Subtitle>
          <SampleQuestions>
            {exampleFlows.map(question => (
              <div key={question}>
                <MessengerIcon />
                <Underlined>{question}</Underlined>
              </div>
            ))}
          </SampleQuestions>
        </div>
        <MessengerContainer className="item order-3 order-mb-2">
          <Circle />
          <FloatingMessenger>
            <StyledMessenger />
          </FloatingMessenger>
        </MessengerContainer>
        <ActionContainer className="item order-4">
          <GreenText>What to do next?</GreenText>
          <GoLiveWithAi {...{ url, client, pipeline }} />
          <OrDivider />
          <SignIntoAi />
          <OrDivider />
          <ShareYourAi {...{ url, client, pipeline }} />
        </ActionContainer>
      </WrapColumn>

      <div>
        <Seabed src={seabed} alt="" />
      </div>
    </MessengerProvider>
  );
}

const WrapColumn = styled.div`
  display: flex;
  flex-flow: column wrap;
  max-height: ${({ maxHeight }) => `${maxHeight + 120}px;`}
  width: 100%;
  align-content: flex-start;
  gap: 0px 60px;
  max-width: 1304px;
  margin: 50px auto 50px;
  padding: 20px 20px 80px;

  @media (max-width: 1300px) {
    max-width: calc(100% - 40px);
    padding: 20px 0px 80px;
  }

  @media (max-width: ${SCREEN_WIDTH.TABLET}px) {
    flex-flow: wrap;
    gap: 30px;
    justify-content: center;
    margin: 20px auto 20px;
    padding-bottom: 110px;
    height: 100%;
    max-height: unset;
  }

  @media (max-width: ${SCREEN_WIDTH.SMALL}px) {
    flex-direction: column;
    align-content: center;
  }

  .item {
    flex: 1 0 auto;
    max-width: calc(33% - 36px);
    width: 100%;

    @media (max-width: ${SCREEN_WIDTH.TABLET}px) {
      max-width: calc(50% - 36px);
      flex: 1 0 auto;
    }

    @media (max-width: ${SCREEN_WIDTH.SMALL}px) {
      max-width: 100%;
    }
  }

  .order-1 {
    order: 1;
  }

  .order-2 {
    order: 2;
  }

  .order-3 {
    order: 3;
  }

  .order-4 {
    order: 4;
  }

  .order-mb-1 {
    @media (max-width: ${SCREEN_WIDTH.MOBILE}px) {
      order: 1;
    }
  }

  .order-mb-2 {
    @media (max-width: ${SCREEN_WIDTH.MOBILE}px) {
      order: 2;
    }
  }

  .order-mb-3 {
    @media (max-width: ${SCREEN_WIDTH.MOBILE}px) {
      order: 3;
    }
  }

  .order-mb-4 {
    @media (max-width: ${SCREEN_WIDTH.MOBILE}px) {
      order: 4;
    }
  }
`;

const Title = styled.h2`
  font-size: 38px;
  font-color: ${colors.DARK_BLUE};
  line-height: 48px;
  font-weight: ${fontWeight.BOLD};
`;

const Subtitle = styled.p`
  font-size: 18px;
  line-height: 22px;
  colors: ${colors.DARK_BLUE};
  max-width: 385px;
`;

const Underlined = styled.span`
  font-weight: ${fontWeight.MEDIUM};
  font-size: 16px;
  position: relative;
  text-decoration: underline solid ${colors.DARK_BLUE} 0.5px;
  text-underline-offset: 5px;
`;

const SampleQuestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  div {
    display: flex;
    gap: 11px;
    align-items: center;

    svg {
      flex-shrink: 0;
    }
  }
`;

const Seabed = styled.img`
  position: fixed;
  width: 100vw;
  bottom: 0;
  right: 0;
  z-index: -5;
  min-width: 1440px;
  overflow: hidden;
`;

const MessengerContainer = styled.div`
  position: relative;
`;

const FloatingMessenger = styled.div`
  margin: auto;
  max-width: 375px;
  overflow: hidden;
  border: 0.5px solid #cdcdcd;
  box-shadow: 0px 3px 13px #06233d33;
  background: #fcfcfc;
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 5/8;
`;

const StyledMessenger = styled(Messenger)`
  width: calc(100% + 2px);
  height: 100%;
  margin-left: -1px;
`;

const Circle = styled.div`
  aspect-ratio: 1;
  width: calc(100% + 58px);
  border-radius: 50%;
  background-color: #eff8f7;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;

  @media (max-width: ${SCREEN_WIDTH.TABLET}px) {
    display: none;
  }
`;

const ActionContainer = styled.div`
  max-width: 384px;
  border-radius: 15px;
  padding: 20px;
  background-color: #00908110;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const GreenText = styled.h3`
  font-size: 22px;
  font-weight: ${fontWeight.BOLD};
  color: ${colors.GREEN};
`;
