import { message } from 'antd';

export const displayError = function (status) {
  switch (status) {
    case 403:
      return message.error('Not Authorised');
    case 404:
      return message.error('Not Found');
    case 500:
      return message.error('Apologies, an internal server error occurred.');
    default:
      return null;
  }
};
