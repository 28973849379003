import React from 'react';
export default function DashboardMenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.932"
      height="13.132"
      viewBox="0 0 12.932 13.132"
    >
      <g
        id="Group_704"
        data-name="Group 704"
        transform="translate(-379.111 -25.009)"
      >
        <g id="aliwangwang-outlined" transform="translate(377 23.01)">
          <path
            id="Path_2121"
            data-name="Path 2121"
            d="M14.77,6.538A6.357,6.357,0,0,0,6.879,2.994a9.687,9.687,0,0,0-3.794-.767c-.037,0-.124,0-.157,0a.6.6,0,0,0-.489.275.617.617,0,0,0-.057.561L3.459,5.772a6.367,6.367,0,0,0-.385,5.708,6.344,6.344,0,0,0,5.847,3.878,6.349,6.349,0,0,0,5.849-8.82ZM13.657,11a5.143,5.143,0,0,1-1.1,1.641,5.051,5.051,0,0,1-1.636,1.1h0A5.154,5.154,0,0,1,4.162,11v0a5.188,5.188,0,0,1,.313-4.638l.221-.37-.159-.4L3.678,3.433a9.642,9.642,0,0,1,1,.094A7.011,7.011,0,0,1,6.6,4.072l.318.149.333-.114a5.155,5.155,0,0,1,6.4,2.878l0,0,0,0a5.16,5.16,0,0,1,0,4.013ZM11.636,6.317a.623.623,0,0,0-.621.621v.923a.621.621,0,1,0,1.243,0V6.938A.622.622,0,0,0,11.636,6.317Zm-2.935,0a.622.622,0,0,0-.621.621v.923a.621.621,0,1,0,1.243,0V6.938A.622.622,0,0,0,8.7,6.317Z"
            transform="translate(-0.228 -0.227)"
            strokeWidth="0.2"
          />
        </g>
      </g>
    </svg>
  );
}
