export default async function exchangeCodeForAccessToken(authURL, clientID) {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const selectedClient = urlParams.get('client');

  const response = await fetch(`${authURL}/auth/tokens`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({
      code,
      client_id: clientID,
      grant_type: 'authorization_code',
      account_id: selectedClient
    })
  });

  if (response.ok) {
    sessionStorage.setItem('selectedClientId', selectedClient);
  }

  const token = await response.json();

  return token.access_token;
}
