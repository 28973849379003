import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthorization } from '../uni-comms-api/hooks/authentication';
import PropTypes from 'prop-types';

export default function AuthorisationGate({ children, permission }) {
  const isAuthorized = useAuthorization(permission);

  if (isAuthorized) {
    return children;
  } else {
    return <Navigate to="/403" />;
  }
}

AuthorisationGate.propTypes = {
  children: PropTypes.node.isRequired,
  permission: PropTypes.string.isRequired
};
