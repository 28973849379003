import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function ServerError() {
  const navigate = useNavigate();

  return (
    <Result
      status="500"
      title="500"
      subTitle="Please try again later whilst we fix the issue."
      extra={
        <Button type="primary" onClick={() => navigate(-1)}>
          Retry
        </Button>
      }
    />
  );
}
