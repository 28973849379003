import { useEffect, useState, useRef } from 'react';
import HttpError from 'standard-http-error';
import { useAuthentication } from './provider';

const inEndless401Loop = lastErrors =>
  lastErrors[0] === 401 && lastErrors[1] === 401;

const EXPIRATION_TIME = 600 * 1000;

export const useFetch = (url, options, initialData = null) => {
  const [lastErrors, setLastErrors] = useState([]);
  const { token, clearToken } = useAuthentication();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(initialData);
  const [refresh, setRefresh] = useState(false);


  const resetTimerRef = useRef(null);

  const runRequestCycle = () => {
    async function request() {
      try {
        setLoading(true);
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          signal: controller.signal,
          ...options
        });

        if (!response.ok) {
          throw new HttpError(response.status);
        }

        const body = await response.json();

        setData(body);
        setError();
        setLastErrors([]);
      } catch (err) {

        if (controller.signal.aborted) {
          return;
        }
        
        if (err.code === 401) {
          clearToken();
        } else {
          setError(
            err.message === 'Failed to fetch'
              ? new HttpError(HttpError.REQUEST_TIMEOUT)
              : new Error(err.message)
          );
        }
        setLastErrors([err.code, ...lastErrors]);
      } finally {
        setLoading(false);
        setRefresh(false);
      }
    }
    const controller = new AbortController();

    // dont get into an endless 401 loop
    if (inEndless401Loop(lastErrors)) {
      if (resetTimerRef.current == null) {
        resetTimerRef.current = setTimeout(() => {
          setLastErrors([]);
          resetTimerRef.current = null;
        }, EXPIRATION_TIME * 0.9);
      }
    } else {
      token && request();
    }
    return controller;
  };

  useEffect(() => {
    const controller = runRequestCycle();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, token, clearToken]);

  useEffect(() => {
    if (!refresh) {
      return;
    }
    const controller = runRequestCycle();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  if (inEndless401Loop(lastErrors)) {
    return [false, null, new HttpError(401)];
  }

  return {
    loading,
    data,
    error,
    refresh: () => setRefresh(true)
  };
};
