import { ReactNode } from 'react';

type Step = {
  header?: string;
  content: ReactNode | ReactNode[];
};

export const COPY_SNIPPET_TEXT = 'Copy the code snippet to your clipboard';

export const SQUARESPACE_STEPS: Step[] = [
  {
    header: 'Go to Squarespace settings',
    content:
      'Once you have logged into your Squarespace website you need to click on Settings -> Advanced -> Code injection.'
  },
  {
    header: 'Paste in code snippet',
    content: 'Paste the code snippet in to the header and press save.'
  },
  {
    header: 'Check out your assistant!',
    content: 'You should now be able to see the assistant on your website.'
  }
];

export const WORDPRESS_PLUGIN = {
  title: 'Use our WordPress plugin',
  description:
    'Install our WordPress plugin that will add your AI assistant to your website for you',
  url: 'https://wordpress.org/plugins/ebi-ai/'
};

export const WORDPRESS_STEPS: Step[] = [
  {
    header: `Go to ‘Theme file editor’ in WordPress`,
    content:
      'Log in to WordPress, click ‘Appearance’ on the sidebar, then ‘Theme file editor’'
  },
  {
    header: 'Paste code snippet',
    content:
      'Once there, you can paste in the code snippet into the file and press update.'
  },
  {
    header: 'Check out your assistant!',
    content: 'You should now be able to see the assistant on your website.'
  }
];

export const SHOPIFY_STEPS: Step[] = [
  {
    header: 'Log into Shopify and navigate to page',
    content: `Select the page you want your assistant to be visible on from the 
    ‘Pages’ menu`
  },
  {
    header: 'Go to HTML editor',
    content:
      'Swap to HTML view using the HTML button in the right-hand corner of the editor.'
  },
  {
    header: 'Paste in code snippet',
    content:
      'Next, paste in the code snippet you copied earlier and press save.'
  },
  {
    header: 'Check out your assistant!',
    content: 'You should now be able to see the assistant on your website.'
  }
];

export const HTML_WITH_GTM_STEPS: Step[] = [
  {
    header: 'Create a new tag',
    content: `Once you have logged in to your Google Tag Manager account you need to click on ‘Tags’ then ‘New’ in the top right.`
  },
  {
    header: 'Configure your tag',
    content: `Click on ‘Tag Configuration’ and choose ‘Custom HTML’ from the list.`
  },
  {
    header: 'Paste in code snippet',
    content:
      'Next, you will need to paste the code snippet you copied earlier into the HTML box.'
  },
  {
    header: 'Select trigger',
    content: `Underneath the ‘Tag Configuration’ section in the ‘Triggering’ section you need to select ‘All Pages’ with the type ‘Page view’.`
  },
  {
    header: 'Publish your tag',
    content: `Finally, press ‘Submit’, give your tag a name and publish it to see your assistant on your site.`
  }
];
