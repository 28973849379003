const apiUrl = process.env.AUTH_URL;

async function getExampleFlows({ clientId, pipelineId }) {
  try {
    const response = await fetch(
      `${apiUrl}/channels/clients/${clientId}/pipelines/${pipelineId}/messenger/examples`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      }
    );

    if (!response.ok) {
      return [];
    }

    const { items } = await response.json();

    return items.map(flow => flow.trigger.intent.name);
  } catch {
    return [];
  }
}

export default getExampleFlows;
