import { useState, useEffect } from 'react';
export default function useDebounce<T>(value: T, duration: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, duration);
    return () => clearTimeout(timeout);
  }, [value, duration]);

  return debouncedValue;
}
