import { useEffect, useState } from 'react';
import { useFetch } from './authentication';
import isEmpty from 'lodash/isEmpty';

export default function useRoles() {
  const [roles, setRoles] = useState({});

  const { data: newRoles, loading, error } = useFetch(
    `${process.env.AUTH_URL}/auth/roles`
  );

  useEffect(() => {
    if (!isEmpty(newRoles)) {
      setRoles(newRoles);
    }
  }, [newRoles]);

  return { roles, loading, error };
}
