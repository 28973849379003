import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DisconnectOutlined } from '@ant-design/icons';

export default function NoInternet() {
  const navigate = useNavigate();

  return (
    <Result
      icon={<DisconnectOutlined />}
      title="No Internet"
      subTitle="You're not connected to the internet."
      extra={
        <Button type="primary" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      }
    />
  );
}
