import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Copy } from '../../assets/copy.svg';

type Props = {
  clientId: string;
  pipelineId: string;
};

const CodeSnippet = ({ clientId, pipelineId }: Props) => {
  const codeline = `<div id="chat_content"></div> 
  <script src="https://messenger.ebiai.app/static/js/main.js"></script>
  <script>
    window.chatBot.init({ 
        client: "${clientId}", 
        pipeline: "${pipelineId}" 
    });
  </script>`;

  const onCopyCode = () => {
    void navigator.clipboard.writeText(codeline);
  };

  return (
    <CodeBlock>
      <CopyButton onClick={onCopyCode} />
      <CodeText>
        {`<div id=`}
        <QuoteText>&quot;chat_content&quot;</QuoteText>
        {`></div>`}
        <br />
        {`<script src=`}
        <QuoteText>
          &quot;https://messenger.ebiai.app/static/js/main.js&quot;
        </QuoteText>
        {`></script>`}
        <br />
        {`<script>`}
        <br />
        {`  window.chatBot.init({`}
        <br />
        {`    client:`}
        <QuoteText>&quot;{clientId}&quot;</QuoteText>,<br />
        {`    pipeline: `}
        <QuoteText>&quot;{pipelineId}&quot;</QuoteText>
        <br />
        {`  });`}
        <br />
        {`</script>`}
      </CodeText>
    </CodeBlock>
  );
};

const CopyButton = styled(Copy)`
  cursor: pointer;
  float: right;
`;
const CodeBlock = styled.div`
  background-color: #f6f6f6;
  padding: 20px 15px;
  border-radius: 10px;
  border: 1px solid #70707026;
`;

const CodeText = styled.pre`
  font-family: 'Roboto', monospace;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
`;

const QuoteText = styled.span`
  color: #729c68;
`;

export default CodeSnippet;
