export default function injectMessenger(messengerUrl) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = messengerUrl;
    script.async = true;
    script.addEventListener('load', resolve);
    script.addEventListener('error', e => reject(e.error));
    document.body.appendChild(script);
  });
}
