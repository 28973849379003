import React from 'react';
import styled from 'styled-components';
export default function ClawsonSupportMenuIcon({ open = false }) {
  return (
    <StyledHover
      open={open}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g
        id="Group_35589"
        data-name="Group 35589"
        transform="translate(-1318 -19)"
      >
        <g
          id="Ellipse_1570"
          data-name="Ellipse 1570"
          transform="translate(1318 19)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          className="circle"
        >
          <circle cx="14" cy="14" r="14" stroke="none" />
          <circle cx="14" cy="14" r="13" fill="none" />
        </g>
        <g
          id="Group_34647"
          data-name="Group 34647"
          transform="translate(1323.408 23.821)"
          className="mark"
        >
          <path
            id="Path_33501"
            data-name="Path 33501"
            d="M146.709,217.954a1.055,1.055,0,1,1-1.055-1.055,1.055,1.055,0,0,1,1.055,1.055"
            transform="translate(-137.181 -203.546)"
            fill="#fff"
            className="mark"
          />
          <path
            id="Path_33502"
            data-name="Path 33502"
            d="M108.314,72.3a3.878,3.878,0,0,0-3.88,3.88h0a1.055,1.055,0,1,0,2.111,0,1.771,1.771,0,1,1,1.771,1.772,1.055,1.055,0,0,0-1.055,1.055v1.13a1.055,1.055,0,1,0,2.111,0v-.221a3.882,3.882,0,0,0-1.057-7.617"
            transform="translate(-99.842 -69.122)"
            fill="#fff"
            className="mark"
          />
        </g>
      </g>
    </StyledHover>
  );
}

const StyledHover = styled.svg`
  ${({ open }) =>
    open &&
    `
    .circle {
      fill: white;
    }
    .mark {
      fill: #06233d !important;
      stroke: #06233d !important;
    }
`}
  &:hover {
    .circle {
      fill: white;
    }
    .mark {
      fill: #06233d !important;
      stroke: #06233d !important;
    }
  }
`;
