import { useCallback } from 'react';
import { useClient } from './authentication';
import Tags from '../Tags';

export default function useTags() {
  const { client, refreshClient } = useClient();
  const { mutateAsync: putTag } = Tags.usePut();

  const tagExists = useCallback(
    tag => (client.tags || []).some(tagObject => tagObject.tag === tag),
    [client]
  );

  const ensureTag = useCallback(
    async tag => {
      await putTag({
        url: `${process.env.AUTH_URL}/clients/${client._id}/tags/${tag}`
      });
      await refreshClient();
    },
    [refreshClient, putTag, client]
  );

  return {
    tagExists,
    ensureTag
  };
}
