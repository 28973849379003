export default async function tryGetUser(authURL, token) {
  const rawResponse = await fetch(`${authURL}/auth/user`, {
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  if (rawResponse.status !== 200) {
    return false;
  }

  return rawResponse.json();
}
