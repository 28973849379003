import React, { ReactNode } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../constants';
import loadingIcon from '../../../assets/loading.svg';
interface Props {
  kind?: 'outlined' | 'filled' | 'outlined-filled' | 'standard';
  isLoading?: boolean;
  children: string | ReactNode;
  color?: 'red' | 'green' | 'white';
  loadingText?: string;
}

const FOCUS_COLOUR = '#7B1118';
const FILLED_HOVER_COLOUR = '#F96C76';
const FILLED_GREEN_HOVER_COLOUR = 'rgba(0, 144, 129, 0.9)';
const OUTLINED_HOVER_COLOUR = 'rgba(234, 85, 95, 0.3)';
const OUTLINED_FILLED_HOVER_COLOUR = '#F9D4D6';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const loadingReveal = keyframes`
  from { width: 0px; }
  to { width: 24px; }
`;

const fadeOut = keyframes`
  from { opacity: 0.3; }
  to { opacity: 0; }
`;

function setColor(color: string | undefined) {
  if (color === 'green') {
    return colors.GREEN;
  }
  if (color === 'white') {
    return colors.WHITE;
  }
  return colors.RED;
}

export default function Button({
  children,
  ...props
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
  const isLoadingText = props?.loadingText ?? 'Loading';
  return (
    <StyledButton {...props}>
      {props.isLoading ? isLoadingText : children}
    </StyledButton>
  );
}

const StyledButton = styled.button<Props>`
  position: relative;
  width: 157px;
  height: 45px;
  font-size: 16px;
  line-height: 25px;
  font-weight: medium;
  border-radius: 30px;
  cursor: pointer;
  border: 2px solid ${props => setColor(props?.color)};
  transition: padding 100ms cubic-bezier(0.4, 0, 0.2, 1);

  ${({ isLoading }) =>
    isLoading &&
    css`
      padding-left: 40px;
      cursor: progress;
      pointer-events: none;

      :before {
        content: '';
        mask: url(${loadingIcon});
        height: 24px;
        position: absolute;
        left: 12%;
        animation: ${spin} 1s infinite linear, ${loadingReveal} 100ms forwards;
        background: white;
      }
    `};

  ${({ kind, color }) => {
    switch (kind) {
      case 'outlined-filled':
        return css`
          background: white;
          color: ${setColor(color)};

          :hover {
            background: ${OUTLINED_FILLED_HOVER_COLOUR};
          }

          :before {
            background: ${setColor(color)};
          }
        `;
      case 'outlined':
        return css`
          background: none;
          color: ${setColor(color)};

          :hover {
            background: ${OUTLINED_HOVER_COLOUR};
          }

          :before {
            background: ${setColor(color)};
          }
        `;
      case 'standard':
        return css`
          background: none;
          border-color: transparent;
          color: ${setColor(color)};

          :hover,
          :focus-visible {
            background-color: ${colors.DARK_BLUE}08;
          }

          :after {
            border-color: ${colors.DARK_BLUE}40;
          }
        `;

      case 'filled':
      default:
        return css`
          background: ${setColor(color)};
          color: white;

          :hover {
            border-color: ${color === 'green'
              ? FILLED_GREEN_HOVER_COLOUR
              : FILLED_HOVER_COLOUR};
            background: ${color === 'green'
              ? FILLED_GREEN_HOVER_COLOUR
              : FILLED_HOVER_COLOUR};
          }

          :disabled {
            background: ${colors.DARK_GRAY};
            color: ${colors.WHITE};
            border: 2px solid ${colors.DARK_GRAY};
          }
        `;
    }
  }}

  :focus-visible {
    border-color: ${FOCUS_COLOUR};
    outline: none;
  }

  :after {
    content: '';
    position: absolute;
    box-sizing: content-box;
    left: -11px;
    top: -11px;
    width: calc(100% + 3px);
    height: calc(100% + 3px);
    border: 10px solid ${props => setColor(props?.color)};
    border-radius: 40px;
    animation: ${fadeOut} 200ms ease-in forwards;
    transition: all 200ms ease-in;
    visibility: hidden;
  }

  :active {
    filter: brightness(85%);

    &:after {
      border-width: 0px;
      left: -2px;
      top: -2px;
      opacity: 0;
      animation: none;
      transition: none;
      visibility: visible;
    }
  }

  :disabled {
    opacity: 0.65;
    cursor: not-allowed;
    &:active {
      filter: none;
    }

    &:after {
      content: none;
    }
  }
`;
