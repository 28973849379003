import { useEffect, useState } from 'react';
import { getInitials } from '../../utils';
import { useSelectedClient, useFetch } from './authentication';

export default function useMembers() {
  const { selectedClient } = useSelectedClient();
  const [members, setMembers] = useState([]);

  const {
    data: newMembers,
    loading,
    refresh
  } = useFetch(`${process.env.AUTH_URL}/clients/${selectedClient}/users`);

  useEffect(() => {
    setMembers(
      newMembers && newMembers.items.length
        ? newMembers.items.map(member => ({
          ...member,
          initials: getInitials(member.fullName)
        }))
        : []
    );
  }, [setMembers, newMembers]);

  return { members, loading, refresh };
}
