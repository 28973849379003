export default async function ping(clientId, userId, userStatus, token) {
  return await fetch(
    `${process.env.AUTH_URL}/clients/${clientId}/user/${userId}/ping`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        date: new Date().toISOString(),
        status: userStatus
      })
    }
  );
}
