import React, { MouseEvent, useRef } from 'react';
import styled from 'styled-components';
import qrIcon from '../../../assets/AI.svg';
import downloadIcon from '../../assets/download.svg';
import { QRCodeCanvas } from 'qrcode.react';
import { useLocation } from 'react-router-dom';

type Props = {
  clientId: string;
  pipelineId: string;
};

export default function QRCodeBlock({ clientId, pipelineId }: Props) {
  const qrRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  const qrURL =
    (process.env.MESSENGER_URL as string) +
    '/?client=' +
    clientId +
    '&pipeline=' +
    pipelineId +
    '&fullscreen=true';

  const downloadQRCode = (e: MouseEvent): void => {
    const qrRefCurrent = qrRef?.current;
    const canvas = qrRefCurrent ? qrRefCurrent.querySelector('canvas') : null;

    e.preventDefault();
    if (canvas) {
      const image = canvas.toDataURL('image/png');
      const anchor = document.createElement('a');
      anchor.href = image;
      anchor.download = `${location.key}-qr.png`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  };

  return (
    <CodeBlock>
      <QRCodeCanvasWrapper ref={qrRef}>
        <QRCodeCanvas
          id="qr-gen"
          value={qrURL}
          size={165}
          level={'H'}
          fgColor={'#06233D'}
          imageSettings={{
            src: qrIcon,
            excavate: true,
            width: 50,
            height: 28
          }}
        />
      </QRCodeCanvasWrapper>
      <InfoContainer>
        <InfoTitle>
          Download your QR code
          <Icon src={downloadIcon} alt="Download" onClick={downloadQRCode} />
        </InfoTitle>
        <InfoDescription>
          Use this QR code and allow your customers instant access to your AI
          assistant. You can stick it on physical items that you sell so your
          customers have in the moment support ensuring their loyalty.
        </InfoDescription>
      </InfoContainer>
    </CodeBlock>
  );
}

const CodeBlock = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f6f6f6;
  padding: 20px 15px;
  border-radius: 10px;
  border: 1px solid #70707026;
`;

const QRCodeCanvasWrapper = styled.div`
  height: 186px;
  width: 187px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 17px;
  padding: 10px;
  margin-top: 20px;
`;

const InfoContainer = styled.div`
  padding: 10px 20px;
  width: 270px;
  h3 {
    font-weight: bold;
    font-size: 20px;
    width: 295px;
  }
`;

const InfoTitle = styled.h3`
  font-weight: bold;
  font-size: 20px;
  width: 295px;
`;

const InfoDescription = styled.p`
  width: 270px;
  height: 151px;
  font-size: 16px;
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 10%;
  cursor: pointer;
  margin-bottom: 10px;
`;
