export default async function logout(authURL, clientID, token) {
  await fetch(`${authURL}/auth/revoke`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      token_type_hint: 'access_token',
      token
    })
  });

  await fetch(`${authURL}/auth/revoke`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({
      token_type_hint: 'refresh_token',
      client_id: clientID
    })
  });
  sessionStorage.removeItem('showTrial');
  window.location.reload();
}
