import React from 'react';
import styled from 'styled-components';
import { Menu } from 'antd';
import { useUserStatus } from '../../../uni-comms-api/hooks/authentication';
import { getStyle, statusList } from '../../../utils';

export const StatusTitle = () => {
  const { userStatus } = useUserStatus();

  return (
    <MenuItemWrapper>
      <Status id="statustitle" bgColor={getStyle(userStatus)} />
      <Title>{statusList.find(x => x.key === userStatus).title}</Title>
    </MenuItemWrapper>
  );
};

export default function StatusSubMenuItems({ ...props }) {
  const { setUserStatus } = useUserStatus();

  return statusList.map(status => (
    <Menu.Item
      key={status.key}
      {...props}
      onClick={() => setUserStatus(status.key)}
    >
      <MenuItemWrapper>
        <StatusDot bgColor={status.style} /> {status.title}
      </MenuItemWrapper>
    </Menu.Item>
  ));
}

const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Status = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  ${({ bgColor }) => bgColor}
`;

const StatusDot = styled(Status)`
  margin-right: 10px;
  ${({ bgColor }) => bgColor}
`;

const Title = styled.div`
  padding-left: 10px;
`;
