import React, { useState, ReactNode } from 'react';
import { ReactComponent as Rocket } from './assets/rocket.svg';
import { ReactComponent as SignIn } from './assets/log-in.svg';
import { ReactComponent as RightArrow } from './assets/right_arrow.svg';
import { ReactComponent as Share } from './assets/share.svg';
import { ReactComponent as Link } from './assets/link.svg';
import { ReactComponent as Copy } from './assets/copy.svg';

import styled from 'styled-components';
import { colors, fontWeight } from '../../constants';
import { Tooltip } from 'antd';

type MessengerSettings = {
  url: string;
  client: string;
  pipeline: string;
};

const CopyButtonContainer = ({
  children,
  textToCopy
}: {
  textToCopy: string;
  children: ReactNode;
}) => {
  const [showCopied, setShowCopied] = useState(false);

  const handleClick = () => {
    void navigator.clipboard.writeText(textToCopy);
    setShowCopied(true);

    setTimeout(() => setShowCopied(false), 500);
  };

  return (
    <Tooltip title="Copied!" open={showCopied}>
      <div onClick={handleClick}>{children}</div>
    </Tooltip>
  );
};

const GoLiveWithAi = ({ url, client, pipeline }: MessengerSettings) => {
  const codeline = `<div id="chat_content"></div> 
    <script src="${url}/static/js/main.js"></script>
    <script>
      window.chatBot.init({ 
          client: "${client}", 
          pipeline: "${pipeline}" 
      });
    </script>`;

  return (
    <ActionContainer>
      <Header>
        <Title>
          <Rocket />
          Go live with your AI
        </Title>
      </Header>
      <div>
        Copy and paste this code snippet into the HTML code of the page you want
        your assistant to appear on.
      </div>
      <CodeBlock>
        <CopyButtonContainer textToCopy={codeline}>
          <CopyButton />
        </CopyButtonContainer>
        <CodeText>
          {`<div id=`}
          <QuoteText>&quot;chat_content&quot;</QuoteText>
          {`></div>`}
          <br />
          {`<script src=`}
          <QuoteText>&quot;{url}/static/js/main.js&quot;</QuoteText>
          {`></script>`}
          <br />
          {`<script>`}
          <br />
          &nbsp;&nbsp;{`window.chatBot.init({`}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;{`client:`}
          <QuoteText>&quot;{client}&quot;</QuoteText>,<br />
          &nbsp;&nbsp;&nbsp;&nbsp;{`pipeline: `}
          <QuoteText>&quot;{pipeline}&quot;</QuoteText>
          <br />
          &nbsp;&nbsp;{`});`}
          <br />
          {`</script>`}
        </CodeText>
      </CodeBlock>
    </ActionContainer>
  );
};

const SignIntoAi = () => (
  <a href="/">
    <ActionContainer>
      <Header>
        <Title>
          <SignIn />
          Sign in to AI Studio to edit your responses & brand your AI
        </Title>
        <RightArrow style={{ cursor: 'pointer' }} />
      </Header>
    </ActionContainer>
  </a>
);

const ShareYourAi = ({ client, pipeline }: MessengerSettings) => {
  const getLandingUrl = () => {
    const { origin, pathname } = window.location;
    return `${origin}${pathname}?client=${client}&pipeline=${pipeline}`;
  };

  return (
    <CopyButtonContainer textToCopy={getLandingUrl()}>
      <ActionContainer>
        <Header>
          <Title>
            <Share />
            Share your AI with colleagues
          </Title>
          <Link />
        </Header>
      </ActionContainer>
    </CopyButtonContainer>
  );
};

const OrDivider = () => (
  <OrContainer>
    <span className="line" />
    Or
    <span className="line" />
  </OrContainer>
);

export { GoLiveWithAi, SignIntoAi, ShareYourAi, OrDivider };

const ActionContainer = styled.div`
  svg {
    flex-shrink: 0;
  }
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  gap: 10px;
  color: ${colors.DARK_BLUE};
  font-size: 16px;
  font-weight: ${fontWeight.MEDIUM};
`;

const OrContainer = styled.div`
  font-style: italic;
  color: ${colors.DARK_BLUE};
  font-size: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: ${fontWeight.BOLD};
  gap: 14px;

  .line {
    height: 1px;
    width: 100%;
    background-color: #06233d1a;
  }
`;

const CopyButton = styled(Copy)`
  cursor: pointer;
  float: right;
`;
const CodeBlock = styled.div`
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #70707026;
  word-break: break-all;
`;

const CodeText = styled.div`
  font-family: 'Roboto', monospace;
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
`;

const QuoteText = styled.span`
  color: #729c68;
`;
