import { useEffect, useState } from 'react';
import {
  useSelectedClient,
  useFetch
} from '../../uni-comms-api/hooks/authentication';

export default () => {
  const { selectedClient } = useSelectedClient();
  const [apps, setApps] = useState([]);

  const { data, loading, error } = useFetch(
    `${process.env.AUTH_URL}/clients/${selectedClient}/apps`
  );

  useEffect(() => {
    if (data) {
      setApps(data);
    }
  }, [data]);

  return { apps, loading, error };
};
