import { useEffect, useState } from 'react';
import { getInitials } from '../../utils';
import {
  useSelectedClient,
  useFetch
} from '../../uni-comms-api/hooks/authentication';

export default teamId => {
  const { selectedClient } = useSelectedClient();
  const [team, setTeam] = useState(null);

  const { data, loading } = useFetch(
    `${process.env.AUTH_URL}/clients/${selectedClient}/team/${teamId}`
  );

  useEffect(() => {
    if (data) {
      setTeam({
        ...data,
        initials: getInitials(data.name)
      });
    }
  }, [data]);

  return { team, loading };
};
