import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import AIAssistantIcon from '../../assets/AIAssistant.svg';
interface PublishFlowsMessageModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}
const PublishFlowsMessageModal: React.FC<PublishFlowsMessageModalProps> = ({
  open = false,
  setOpen
}) => {
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <StyledModal
      open={open}
      width={600}
      footer={false}
      maskStyle={{ backdropFilter: 'blur(8px) brightness(0.4)' }}
      onCancel={handleCancel}
    >
      <ImageContainer>
        <Image />
      </ImageContainer>
      <Title>
        To test your AI assistant, you&apos;ll need to have at least one flow
        published.
      </Title>
      <StyledText>
        Please follow the steps below to publish a flow and start testing your
        your assistant:
      </StyledText>
      <InfoContainer>
        <StyledListText>
          Go to AI studio and create or review your flows.
        </StyledListText>
        <StyledListText>
          For each flow you&apos;d like to publish, click the
          &apos;Publish&apos; button or &apos;Publish all&apos;
        </StyledListText>
        <StyledListText>
          Once you have published one or more flows, you can return to the
          testing area and try out your AI assistant.
        </StyledListText>
      </InfoContainer>
      <StyledText>
        Remember, the more flows you publish, the more sophisticated your
        assistant&apos;s interactions will be. Happy testing!
      </StyledText>
    </StyledModal>
  );
};
const StyledModal = styled(Modal)`
  text-align: center;
  .ant-modal-root {
    max-width: 50%;
  }
`;
const Title = styled.h2`
  text-align: left;
  font-weight: normal normal medium 16px/24px;
  font-size: 18px;
  line-height: 24px;
  margin: 6px 35px 20px 23px;
  padding: 5px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 10px;
`;

const Image = styled.div`
  width: 285px;
  height: 137px;
  background: transparent url('${AIAssistantIcon}') 0% 0% no-repeat padding-box;
  background-size: cover;
  opacity: 1;
`;

const StyledText = styled.div`
  text-align: left;
  font-weight: normal normal medium 16px/22px;
  margin: 0 30px 15px 24px;
  font-size: 16px;
`;
const InfoContainer = styled.ol`
  counter-reset: list-number;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 30px 24px 0px;
`;
const StyledListText = styled.li`
  text-align: left;
  font-weight: normal normal medium 16px/22px;
  font-size: 16px;
`;
export default PublishFlowsMessageModal;
