import React from 'react';
export default function ChannelMenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.487"
      height="19"
      viewBox="0 0 22.487 19"
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_15428" data-name="Rectangle 15428" width="22.487" height="19" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_14117" data-name="Group 14117" transform="translate(0 0)">
        <g id="Group_14116" data-name="Group 14116" transform="translate(0 0)" clipPath="url(#clip-path)">
          <path id="Path_35088" data-name="Path 35088" d="M20.022,12.709a4.793,4.793,0,0,0-.04-5.9l-.042-.048a5.947,5.947,0,0,0-7.894-.79,3.536,3.536,0,0,0-1.478,2.1,4.272,4.272,0,0,1-.712,1.688.539.539,0,0,0,.082.663l.026.026.324.329c.069.068.068.1.068.113,0,.231-.464.672-.741.941-.512.491-.838.846-.711,1.291.12.417.522.5.907.585.106.023.225.049.352.084l.034.009,0,0a2.386,2.386,0,0,1,.27,1.854,1.027,1.027,0,0,0,.054,1.069,1.306,1.306,0,0,0,1.354.464c.366-.061.726-.125,1.075-.192a3.6,3.6,0,0,1,.388.823.538.538,0,0,0,.517.38h5.9a.541.541,0,0,0,.413-.89,3.676,3.676,0,0,1-.143-4.6M11.513,14.5a5.244,5.244,0,0,0-.2-.712c-.052-.164-.1-.315-.119-.392a.924.924,0,0,0-.744-.648l-.178-.045.093-.091A2.689,2.689,0,0,0,11.44,10.9a1.237,1.237,0,0,0-.38-.887L11,9.957a4.9,4.9,0,0,0,.621-1.627,2.467,2.467,0,0,1,1.048-1.475,4.868,4.868,0,0,1,6.462.626,3.716,3.716,0,0,1,.028,4.571v0a4.774,4.774,0,0,0-.417,5.064H14.233a5.5,5.5,0,0,0-.591-1.036.534.534,0,0,0-.551-.217c-.443.091-.913.178-1.388.256-.152.024-.24.006-.267.022l.037-.087a3.6,3.6,0,0,0,.04-1.559" transform="translate(1.468 0.798)" fill="#fff" />
          <path id="Path_35089" data-name="Path 35089" d="M10.42.5H3.058a2.558,2.558,0,1,0,0,5.115H7.574l3.032,1.942L10.645,5.6A2.557,2.557,0,0,0,10.42.5Z" transform="translate(0.083 0.082)" fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="1" />
          <line id="Line_721" data-name="Line 721" y2="0.001" transform="translate(3.943 3.138)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
          <line id="Line_722" data-name="Line 722" y2="0.001" transform="translate(6.822 3.138)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
          <line id="Line_723" data-name="Line 723" y2="0.001" transform="translate(9.7 3.138)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" />
        </g>
      </g>
    </svg>
  );
}
