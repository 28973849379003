import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../constants';
import { CloseOutlined } from '@ant-design/icons';

import DisplayConfetti from '../utils/DisplayConfetti';
import TestAIButton from './buttons/TestAiButton';
import ClawsonWithConfetti from '../assets/clawson-confetti.svg';

import useAutoGen, { StatusType } from '../uni-comms-api/hooks/useAutoGen';
import useWindowVisible from '../uni-comms-api/hooks/useWindowVisible';

const AICreatedBanner: React.FC = () => {
  const windowVisible = useWindowVisible();
  const confettiLimitInSecs = 5;
  const SHOW_AI_CREATED_BANNER = 'showAICreatedBanner';
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const { data: autoFlowsGen } = useAutoGen();

  useEffect(() => {
    const showAICreatedBanner: string | null = localStorage.getItem(
      SHOW_AI_CREATED_BANNER
    );

    if (showAICreatedBanner !== 'false') {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  const closeTag = () => {
    setIsVisible(false);
    localStorage.setItem(SHOW_AI_CREATED_BANNER, JSON.stringify(false));
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (
      autoFlowsGen &&
      autoFlowsGen.status === StatusType.COMPLETE &&
      isVisible &&
      windowVisible
    ) {
      setShowConfetti(true);
      timeout = setTimeout(() => {
        setShowConfetti(false);
      }, confettiLimitInSecs * 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [autoFlowsGen, isVisible, windowVisible]);

  if (
    autoFlowsGen &&
    autoFlowsGen.status === StatusType.COMPLETE &&
    isVisible
  ) {
    return (
      <Container>
        <Text>
          <ClawsonWithConfettiLogo
            src={ClawsonWithConfetti}
            alt="clawson-confetti"
          />
          <Label>
            Congratulations - your AI assistant has been created and is ready to
            meet you
          </Label>
          <TestAIButton color="white" kind="outlined" showSplashScreen />
        </Text>
        <CloseOutlined
          size={12}
          style={{ margin: '17px 0px' }}
          onClick={closeTag}
        />
        {showConfetti && <DisplayConfetti />}
      </Container>
    );
  }

  return null;
};

export default AICreatedBanner;

const Container = styled.div`
  background: ${colors.GREEN};
  color: white;
  font-size: 16px;
  padding: 5px 20px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  z-index: 100;
`;

const Text = styled.p`
  flex: 1;
  margin: 0;
`;

const Label = styled.label`
  margin-right: 30px;
`;

const ClawsonWithConfettiLogo = styled.img`
  margin-right: 20px;
`;
