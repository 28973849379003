import React from 'react';
import { useMessenger } from './messenger/provider';

export default function Messenger({ className }) {

  useMessenger({
    embed: {
      enabled: true,
      target: 'chat_content_assistant'
    },
  });
  return <div id="chat_content_assistant" className={className} style={{ zIndex: 1040 }} />;
}
