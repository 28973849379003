import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu } from 'antd';

import { useSelectedClient } from '../../../shell';

import useBots from '../../../uni-comms-api/hooks/useBots';
import useClients from '../hooks/useClients';
import useLicence from '../../../uni-comms-api/hooks/useLicence';
import {
  useAuthentication,
  useUserStatus,
  useSelectedBot,
  useAuthorization
} from '../../../uni-comms-api/hooks/authentication';

import StatusSubMenuItems, { StatusTitle } from './StatusSubMenuItems';
import SelectClientSubMenuItems from './SelectClientSubMenuItems';
import MenuHeader from './MenuHeader';
import SelectedClientTitle from './SelectedClientTitle';
import SelectedBotTitle from './SelectedBotTitle';

import BotMenuItems from './BotMenuItems';

import './antOverrides.css';
import { Link } from 'react-router-dom';
import DeleteAccountModal from './DeleteAccountModal';

const { SubMenu } = Menu;

const MAX_SUBMENU_DISPLAY_ITEMS = 10;

export default function MenuOverlay() {
  const { logout, user } = useAuthentication();
  const { data: licence } = useLicence();
  const { clients } = useClients();
  const { bots } = useBots();
  const { selectedClient } = useSelectedClient();
  const { isSelfServe, selectedBot } = useSelectedBot();
  const { setUserStatus } = useUserStatus();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const hasBillingWritePermission = useAuthorization('billing:write');

  const handleClick = async () => {
    setUserStatus('offline');
    await logout();
  };

  const clientPopupClassName = '';
  const botPopupClassName =
    bots.length > MAX_SUBMENU_DISPLAY_ITEMS ? 'avatar-menu-scrollable' : '';


  if (!selectedBot || !selectedClient) {
    return null;
  }

  return (
    <>
      <MainMenu>
        <MenuHeader />
        <Menu.Divider />
        <ClientSubMenu
          popupClassName={clientPopupClassName}
          title={<SelectedClientTitle />}
          clientLength={clients.length}
        >
          <SelectClientSubMenuItems />
        </ClientSubMenu>
        <Menu.Divider />
        <ClientBotMenu
          popupClassName={botPopupClassName}
          title={<SelectedBotTitle />}
          bots={bots.length}
        >
          <BotMenuItems bots={bots} />
        </ClientBotMenu>
        <Menu.Divider />
        <StatusSubMenu title={<StatusTitle />}>
          <StatusSubMenuItems />
        </StatusSubMenu>
        <Menu.Divider />
        {licence && licence.tier !== 'enterprise' && isSelfServe && hasBillingWritePermission && (
          <Menu.Item key="plan">
            <Link to={`/plan`}>Plan</Link>
          </Menu.Item>
        )}
        <Menu.Item key="profile">
          <Link to={`/settings/members/view/me`}>Profile</Link>
        </Menu.Item>
        {user.clientRoles[selectedClient].includes('super_admin') && (
          <Menu.Item key="account">
            <Link to={`/settings`}>Account</Link>
          </Menu.Item>
        )}
        <Menu.Item key="deleteAccount" onClick={() => setOpenDeleteModal(true)}>
          Delete account
        </Menu.Item>
        <Menu.Item key="logout" onClick={handleClick}>
          Log out
        </Menu.Item>
      </MainMenu>
      <DeleteAccountModal
        open={openDeleteModal}
        onCancel={() => setOpenDeleteModal(false)}
        onOk={() => setOpenDeleteModal(false)}
      />
    </>
  );
}

const MainMenu = styled(Menu)`
  margin-top: 10px;
  right: -7px;

  :after {
    content: '';
    position: absolute;
    bottom: 99%;
    right: 16px;
    border: 11px solid transparent;
    border-bottom-color: white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
`;

const ClientSubMenu = styled(SubMenu)`
  .ant-dropdown-menu-submenu-title {
    display: flex;
    align-items: center;
  }

  .ant-dropdown-menu-submenu-expand-icon {
    display: ${({ clientLength }) => (clientLength === 1 ? 'none' : 'block')};
  }
`;

const ClientBotMenu = styled(SubMenu)`
  .ant-dropdown-menu-submenu-title {
    display: flex;
    align-items: center;
  }

  .ant-dropdown-menu-submenu-expand-icon {
    display: ${({ bots }) => (bots === 1 ? 'none' : 'block')};
  }
`;

const StatusSubMenu = styled(SubMenu)`
  .ant-dropdown-menu-submenu-title {
    display: flex;
    align-items: center;
  }
`;
