import React, {
  useState,
  KeyboardEventHandler,
  ChangeEvent,
  useEffect
} from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants';
import Button from '../../../modules/flows/presentational/Button';
import EmailSVG from '../../assets/email.svg';
import { Modal, Typography, Input, Tag } from 'antd';
import { useModalManager } from '../ModalManager';

type Props = {
  clientId: string;
  pipelineId: string;
};

const EmailBlock = ({ clientId, pipelineId }: Props) => {
  const modalId = 'email-block';
  const { openModal, closeModal, isActiveModal } = useModalManager();
  const [emails, setEmails] = useState<string[]>([]);
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');
  const subject = 'Code for your AI assistant';
  const body = encodeURIComponent(
    `Copy and paste this code snippet into the HTML code of the page you want your assistant to appear on.


<div id="chat_content"></div> 
<script src="https://messenger.ebiai.app/static/js/main.js"></script>
<script>
  window.chatBot.init({ 
      client: "${clientId}", 
      pipeline: "${pipelineId}" 
  });
</script>`
  );

  const sendEmail = () => {
    const finalEmailList = [...emails];

    if (
      isValidEmail(currentEmail) &&
      !emails.includes(currentEmail.trim().toLowerCase())
    ) {
      finalEmailList.push(currentEmail.trim().toLowerCase());
    }

    window.location.href = `mailto:${finalEmailList.toString()}?subject=${subject}&body=${body}`;
    setCurrentEmail('');
    setErrorMessage('');
    setEmails(finalEmailList);
  };

  const deleteEmail = (emailToDelete: string) => {
    const newEmails = emails.filter(email => email !== emailToDelete);
    setEmails(newEmails);
  };

  const isValidEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onEnterEmail: KeyboardEventHandler<HTMLInputElement> = () => {
    if (isValidEmail(currentEmail)) {
      if (emails.includes(currentEmail.trim().toLowerCase())) {
        setErrorMessage('Email has already been added to recipients list');
      } else {
        setEmails([...emails, currentEmail.trim().toLowerCase()]);
        setCurrentEmail('');
        setErrorMessage('');
      }
    } else {
      setErrorMessage('Please enter a valid email address');
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentEmail(e.target.value);
    if (!isValidEmail(currentEmail)) {
      setErrorMessage('Please enter a valid email address');
    } else if (emails.includes(currentEmail.trim())) {
      setErrorMessage('Email has already been added to recipients list');
    } else {
      setErrorMessage('');
    }
  };

  const cancelModal = () => {
    closeModal();
    setCurrentEmail('');
    setErrorMessage('');
    setEmails([]);
  };

  useEffect(() => {
    if (currentEmail.length === 0) {
      setErrorMessage('');
    }
  }, [currentEmail]);

  return (
    <Container>
      <Description>
        If you prefer to send instructions to your engineering team who can put
        your AI live for you
      </Description>
      <SendEmailButton kind="outlined" onClick={() => openModal(modalId)}>
        <ButtonContainer>
          <EmailIcon src={EmailSVG} />
          Send instructions via email
        </ButtonContainer>
      </SendEmailButton>
      <StyledModal
        open={isActiveModal(modalId)}
        onCancel={cancelModal}
        footer={null}
        closable
        width={620}
      >
        <Heading level={4}>Send instruction via email</Heading>
        <SubHeader>
          If you prefer to send instructions to your engineering team who can
          put your AI live for you. Please enter email address(es) below:
        </SubHeader>
        <EmailFormContainer>
          <EmailAddressInput
            placeholder="Email"
            value={currentEmail}
            onPressEnter={onEnterEmail}
            onChange={handleChange}
          />
          <SubmitButton onClick={sendEmail}>Submit</SubmitButton>
        </EmailFormContainer>
        <ErrorMessage type="danger">{errorMessage}</ErrorMessage>
        {emails.map(email => {
          return (
            <EmailTag key={email} closable onClose={() => deleteEmail(email)}>
              {email}
            </EmailTag>
          );
        })}
      </StyledModal>
    </Container>
  );
};

export default EmailBlock;

const Description = styled.p`
  color: ${colors.DARK_BLUE};
  font-size: 14px;
  line-height: 22px;
  margin: 0px;
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
`;

const SendEmailButton = styled(Button)`
  width: max-content;
  font-size: 16px;
  line-height: 22px;
  border-width: 1px;
`;
const EmailIcon = styled.img`
  width: 15px;
  height: 11px;
  margin-right: 5px;
  margin-bottom: 2px;
`;

const ButtonContainer = styled.div`
  margin: 0px 10px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 40px;
    width: 620px;
  }
`;

const Heading = styled(Typography.Title)`
  color: ${colors.DARK_BLUE};
`;

const SubHeader = styled(Typography.Paragraph)`
  color: ${colors.DARK_BLUE};
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 18px;
`;

const EmailFormContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 10px 10px;
`;

const EmailAddressInput = styled(Input)`
  width: 405px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  background: #f6f6f6 0% 0% no-repeat padding-box;
`;

const SubmitButton = styled(Button)`
  width: 98px;
  height: 45px;
`;

const ErrorMessage = styled(Typography.Text)`
  display: block;
  margin: -5px 0px 10px 10px;
`;

const EmailTag = styled(Tag)`
  border: 0.5px solid #06233d;
  border-radius: 23px;
  opacity: 1;
  padding: 7px 12px;
  font-size: 16px;
  line-height: 22px;
  margin-top: 10px;
`;
