import React from 'react';
import { Menu } from 'antd';
import styled from 'styled-components';
import { useLocation, Link } from 'react-router-dom';

export default function SubMenu() {
  const location = useLocation();
  const selectedKeys = location.pathname.split('/').slice(1);

  return (
    <LiveChatSubMenu
      defaultSelectedKeys={['1']}
      selectedKeys={selectedKeys}
      mode="horizontal"
    >
      <Menu.Item key="live-chat">
        <Link to="/live-chat">Chat</Link>
      </Menu.Item>
      <Menu.Item key="teams">
        <Link to="/teams">Teams</Link>
      </Menu.Item>
    </LiveChatSubMenu>
  );
}

const LiveChatSubMenu = styled(Menu)`
  flex-grow: 0;

  &.ant-menu-horizontal {
    border: none;
    background: transparent;
    padding: 20px 10px 0 20px;
    font-weight: 500;
    font-size: 16px;
  }
`;
