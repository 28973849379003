export const colors = {
  DARK_BLUE: '#06233D',
  RED: '#EA555F',
  WHITE: '#ffffff',
  GREEN: '#009081',
  GREY: '#e9ebee',
  GREY_TEXT: '#CFCFCF',
  BACKGROUND: '#E5F3F2',
  DARK_GRAY: '#BFCCD8',
  ORANGE: '#F4A261',
  BLUE: '#2A7DBC',
  PURPLE: '#A020F0',
  LIGHT_BLUE: '#44BBD8'
};

export const fontWeight = {
  NORMAL: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700
};

export const LANGUAGE_CODES = [
  'af',
  'ar-ae',
  'ar-bh',
  'ar-dz',
  'ar-eg',
  'ar-iq',
  'ar-jo',
  'ar-kw',
  'ar-lb',
  'ar-ly',
  'ar-ma',
  'ar-om',
  'ar-qa',
  'ar-sa',
  'ar-sy',
  'ar-tn',
  'ar-ye',
  'be',
  'bg',
  'ca',
  'cs',
  'cy',
  'da',
  'de',
  'de-at',
  'de-ch',
  'de-li',
  'de-lu',
  'el',
  'en',
  'en-au',
  'en-bz',
  'en-ca',
  'en-gb',
  'en-ie',
  'en-jm',
  'en-nz',
  'en-tt',
  'en-us',
  'en-za',
  'es',
  'es-ar',
  'es-bo',
  'es-cl',
  'es-co',
  'es-cr',
  'es-do',
  'es-ec',
  'es-gt',
  'es-hn',
  'es-mx',
  'es-ni',
  'es-pa',
  'es-pe',
  'es-pr',
  'es-py',
  'es-sv',
  'es-uy',
  'es-ve',
  'et',
  'eu',
  'fa',
  'fi',
  'fo',
  'fr',
  'fr-be',
  'fr-ca',
  'fr-ch',
  'fr-lu',
  'ga',
  'gd',
  'he',
  'hi',
  'hr',
  'hu',
  'id',
  'is',
  'it',
  'it-ch',
  'ja',
  'ji',
  'ko',
  'ku',
  'lt',
  'lv',
  'mk',
  'ml',
  'ms',
  'mt',
  'nb',
  'nl',
  'nl-be',
  'nn',
  'no',
  'pa',
  'pl',
  'pt',
  'pt-br',
  'rm',
  'ro',
  'ro-md',
  'ru',
  'ru-md',
  'sb',
  'sk',
  'sl',
  'sq',
  'sr',
  'sv',
  'sv-fi',
  'th',
  'tn',
  'tr',
  'ts',
  'ua',
  'ur',
  've',
  'vi',
  'xh',
  'zh-cn',
  'zh-hk',
  'zh-sg',
  'zh-tw',
  'zu'
];

export const MODALS = {
  HIDE_FLOWS_WELCOME_MODAL: 'hideFlowsWelcomeModal',
  HIDE_AUTOGEN_MODAL: 'hideAutogenModal'
};

export const OPENAPI_ACCEPTED_FILE_TYPES: string[] = ['json', 'yml', 'yaml'];
export const OPENAPI_ALLOWED_FILE_SIZE_IN_MB = 2;

export const openApiErrorMsg = {
  invalidFileType:
    'Invalid file type. Only .json, .yml and .yaml files are accepted.',
  checkOpenAPIVersion: 'Kindly check your OpenAPI version.',
  requiredServerObjects: 'The servers object is required.',
  pathAndOperationsRequired: 'Available paths and operations are required.',
  processingError: "Sorry, we're unable to process the uploaded file.",
  createConnectorError:
    "Sorry, we're unable to create your connector. Please try again later.",
  openAPIReq:
    'Kindly upload an OpenAPI file or provide a valid OpenAPI specification document link.',
  fileTooLarge: 'File size must be less than 2MB.'
};

export const OutcomeText = {
  SUCCESS: 'Success. Your request has been seen before',
  SIMILAR:
    'Your request is similar to one that has been seen before and new training has been added to existing flow',
  AMBIGUOUS: 'The request was too vague to be understood',
  OUT_OF_SCOPE:
    'The request was out of scope and not relevant to your AI assistant',
  NEW_FLOW: 'Your request is a new one and a new flow has been created',
  NO_OUTCOME: 'No action taken' 
};

export const SCREEN_WIDTH = {
  TABLET: 1024,
  SMALL: 800,
  MOBILE: 480
};

export const BannerState = {
  UNREAD: 'unread',
  READ: 'read',
  DISMISSED: 'dismissed'
};
