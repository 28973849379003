import React from 'react';
import Confetti from 'react-confetti';

import useWindowSize from '../uni-comms-api/hooks/useWindowSize';

const DisplayConfetti: React.FC = () => {
  const windowSize = useWindowSize();

  return (
    <Confetti
      width={windowSize.width}
      height={windowSize.height}
    />
  );
};

export default DisplayConfetti;
