import HttpError from 'standard-http-error';

export default async function exchangeRefreshTokenForAccessToken(
  authURL,
  clientID
) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const currentClient = urlParams.get('client');
    const savedClient = sessionStorage.getItem('selectedClientId');

    const response = await fetch(`${authURL}/auth/tokens`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        client_id: clientID,
        grant_type: 'refresh_token',
        account_id: currentClient || savedClient
      })
    });

    if (!response.ok) {
      throw new HttpError(
        response.status,
        response.message || 'Internal Server Error'
      );
    }

    const token = await response.json();

    return {
      access_token: token.access_token,
      expires_in: token.expires_in
    };
  } catch (err) {
    return {
      access_token: undefined,
      expires_in: 0
    };
  }
}
